var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("configure-warp", { attrs: { title: _vm.label } }, [
    _c("div", { staticClass: "mb10 f-grey f12" }, [
      _vm._v(_vm._s(_vm.mOptions.note)),
    ]),
    _c(
      "div",
      { staticClass: "nav" },
      [
        _vm.edit
          ? _vm._t("default")
          : _vm._l(_vm.mValue, function (item, mIndex) {
              return _c(
                "div",
                { key: item.id, staticClass: "nav-item" },
                [
                  _c(
                    "el-collapse",
                    {
                      model: {
                        value: _vm.activeNames,
                        callback: function ($$v) {
                          _vm.activeNames = $$v
                        },
                        expression: "activeNames",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { staticClass: "wrap-title", attrs: { name: item.id } },
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.mOptions.barText
                                      ? _vm.mOptions.barText
                                      : "配置"
                                  ) +
                                  _vm._s(mIndex + 1) +
                                  ": " +
                                  _vm._s(_vm.mOptions.needCopy ? item.id : "") +
                                  "    "
                              ),
                              _vm.mOptions.needCopy
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "info",
                                        size: "mini",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.copyHandler(item.id)
                                        },
                                      },
                                    },
                                    [_vm._v("复制ID")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._l(_vm.schema.child, function (val, key, index) {
                            return _c(
                              _vm.typeToComponent[val.type],
                              _vm._b(
                                {
                                  key: index,
                                  tag: "component",
                                  model: {
                                    value: item[key],
                                    callback: function ($$v) {
                                      _vm.$set(item, key, $$v)
                                    },
                                    expression: "item[key]",
                                  },
                                },
                                "component",
                                val,
                                false
                              )
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "nav-delete",
                      on: {
                        click: function ($event) {
                          return _vm.delItem(item.id)
                        },
                      },
                    },
                    [_vm._v("x")]
                  ),
                ],
                1
              )
            }),
        _vm.showBtn
          ? _c("div", { staticClass: "nav-add", on: { click: _vm.addItem } }, [
              _vm._v(" " + _vm._s(_vm.mOptions.addBtnText || "新增数据") + " "),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }