<template>
  <div class="schema-form">
    <div class="form-label-text">
      <div class="w99">表单关联ID</div>
      <div class="form-body">
        <el-input v-model="mValue.formId" size="small" placeholder="请输入表单ID"></el-input>
      </div>
    </div>
    <div class="f-red f13 mb15 pl10 pr10"></div>
    <div class="form-label-text">
      <div class="w99">按钮文案(必填)</div>
      <div class="form-body">
        <el-input v-model="mValue.btnText" size="small" placeholder="最多6个字"></el-input>
      </div>
    </div>
    <div class="f-red f13 mb15 pl10 pr10"></div>
    <div class="form-label-text">
      <div class="w99">{{ label }}</div>
      <div class="form-body">
        <el-radio-group v-model="mValue.isconfig" @change="handleRadio">
          <el-radio v-for="(item, index) in data" :key="index" :label="item.value">
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <template v-if="mValue.isconfig === 'config'">
      <div class="formdata-config" v-if="radioOption.length > 0">
        <p class="fontbold">表单数据配置</p>
        <div class="border1">
          <p class="fontbold">单选标题</p>
          <div>
            <el-checkbox-group v-model="checkedOptionString" @change="changeCheckedOption">
              <el-checkbox
                v-for="(option, index) in radioOptionString"
                :label="option"
                :key="index"
                :disabled="optionStatus"
                >{{ option }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="edit-btn" @click="saveCheckedOption">
            {{ !optionStatus ? "保存" : "修改" }}
          </div>
        </div>
        <div class="border1" v-if="mValue.checkedOption.length > 0">
          <p class="fontbold">表单事件配置</p>
          <div class="edit-btn" @click="editOption">添加选项(组合)</div>
          <div class="config-item" v-for="(opt, index) in mValue.showSeleceOption" :key="opt.val">
            <el-collapse>
              <el-collapse-item :title="opt.val" class="wrap-title" :name="opt.index">
                <!-- <p>选项组合:{{ opt.val }}</p> -->
                <div>
                  <p style="line-height: 10px">企微活码:</p>
                  <el-input
                    v-model="opt.qrcode"
                    size="small"
                    placeholder="请输入企微活码"
                  ></el-input>
                </div>
                <div style="margin: 5px 0">
                  <el-button
                    type="primary"
                    icon="el-icon-plus"
                    style="margin: 5px 0"
                    @click="openCoueseSelect(index)"
                    >选择课程:</el-button
                  >
                  <CourseSelect
                    ref="courseRef"
                    v-model="opt.courseId"
                    :options="opt.mOptions"
                    :queryparam="{ productSysProductType: 2, isFree: true }"
                  />
                  <el-input
                    disabled
                    v-if="opt.courseId.length > 0"
                    :value="handleIdstr(opt.courseId)"
                    size="small"
                    placeholder="请输入ID"
                  ></el-input>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="border1">
          <p class="fontbold">表单样式配置</p>
          <div class="option-style">
            <div class="single-option" v-for="(item, index) in mValue.optionStyle" :key="index">
              <div style="width: 60%">{{ item.title }}</div>
              <div style="width: 35%">
                <el-select
                  v-model="item.styleVal"
                  placeholder="请选择样式"
                  value-key="name"
                  width="500"
                  @change="changeStyle"
                >
                  <el-option :key="1" label="一行一个" :value="1"></el-option>
                  <el-option :key="2" label="一行两个" :value="2"></el-option>
                  <el-option :key="3" label="一行三个" :value="3"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="mValue.isconfig === 'notconfig'">
      <div class="no-formdata-config">
        <!-- <div>
          企微活码:
          <el-input v-model="mValue.qrcode" size="small" placeholder="请输入企微活码"></el-input>
        </div> -->
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            style="margin: 5px 0"
            @click="openCoueseSelect(-1)"
            >选择课程:</el-button
          >
          <CourseSelect
            ref="courseRef"
            v-model="mValue.courseId"
            :options="mValue.options"
            :queryparam="{ productSysProductType: 2, isFree: true }"
          />
          <el-input
            disabled
            v-if="mValue?.courseId?.length > 0"
            :value="handleIdstr(mValue.courseId)"
            size="small"
            placeholder="请输入ID"
          ></el-input>
        </div>
        <div class="border2">
          <p class="fontbold">表单样式配置</p>
          <div class="option-style">
            <div class="single-option" v-for="(item, index) in mValue.optionStyle" :key="index">
              <div style="width: 60%">{{ item.title }}</div>
              <div style="width: 35%">
                <el-select
                  v-model="item.styleVal"
                  placeholder="请选择样式"
                  value-key="name"
                  width="500"
                  @change="changeStyle"
                >
                  <el-option :key="1" label="一行一个" :value="1"></el-option>
                  <el-option :key="2" label="一行两个" :value="2"></el-option>
                  <el-option :key="3" label="一行三个" :value="3"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <el-dialog
      title="配置单选项"
      :visible.sync="dialogVisible"
      width="40%"
      append-to-body
      :before-close="() => (dialogVisible = false)"
    >
      <div class="schema-form-dialog">
        <el-form ref="form" :model="formData" label-width="80px">
          <div class="add-btn" @click="addFormOptions">+</div>
          <div class="formOptions" v-for="(ele, index) in formData.formOptions" :key="index">
            <div class="schema-form-item">
              <el-form-item v-for="item in ele.optionGroup" :key="item.id">
                <el-select
                  v-model="item.selectVal"
                  :placeholder="`请选择${item.title}`"
                  value-key="name"
                  width="500"
                >
                  <el-option v-for="opt in item.options" :key="opt" :label="opt" :value="opt">
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="sub-btn" @click="subFormOptions(index)">-</div>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="conformOption">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { getFormConfig } from "@/api/common.js";
import CourseSelect from "@/components/CourseSelect";
import { Message } from "element-ui";

export default {
  name: "SchemaForm",
  components: { CourseSelect },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      formConfig: [],
      radioOption: [],
      radioOptionString: [],
      checkedOptionString: [],
      dialogVisible: false,
      formData: {},
      activeNames: ["1"],
      optionStatus: true,
    };
  },
  mixins: [schemaMixin],
  // computed: {   },
  watch: {
    "value.formId": {
      handler: function (val, oldVal) {
        console.log("SchemaForm.value.", val, oldVal);
        this.formId = val;
        if (!oldVal || val !== oldVal) {
          this.fetchFormConfig(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeCheckedOption(data) {
      console.log("555555=>changeCheckedOption: ", data);
    },
    saveCheckedOption() {
      console.log("555555=>saveCheckedOption.checkedOption: ", this.checkedOptionString);
      this.mValue.checkedOption = this.radioOption.filter((item) => {
        return this.checkedOptionString.includes(item.title);
      });
      if (!this.optionStatus) {
        Message({
          type: "success",
          message: "修改成功!",
          duration: 1000,
        });
        // 配置选项修改时,清空下面的已选项
        this.mValue.tempFormOptions = [];
        this.mValue.showSeleceOption = [];
      }

      this.optionStatus = !this.optionStatus;
    },
    changeStyle() {
      this.$set(this.mValue, "optionStyle", this.$cloneDeep(this.mValue.optionStyle));
      console.log("mValue.optionStyle变了: ", this.mValue.optionStyle);
    },

    fetchFormConfig(id) {
      // console.log("SchemaForm:=>id: ", id);
      this.formConfig = [];
      this.radioOption = [];
      // this.mValue.optionStyle = [];
      this.formData = {};
      if (id) {
        getFormConfig(id).then((res) => {
          // console.log("SchemaForm:=>res.data ", res);
          if (res.data) {
            this.formConfig = res.data.content;
            this.radioOption = res.data.content?.filter(
              (item) => item.type === "radio" || item.type === "select"
            );
            this.radioOptionString = this.radioOption?.map((opt) => opt.title);
            console.log("55555=>this.radioOption", this.radioOption, this.radioOptionString);
            console.log("this.mValue?.optionStyle?.length: ", this.mValue?.optionStyle?.length);
            let tempArr = [];
            tempArr = res.data.content?.filter(
              (item) => item.type === "radio" || item.type === "checkbox"
            );
            tempArr.forEach((fe) => {
              const tempFe = this.mValue?.optionStyle.find((fi) => fi.id === fe.id);
              if (tempFe && tempFe.styleVal) {
                fe.styleVal = tempFe.styleVal;
              }
            });
            console.log(".optionStyle=>tempArr: ", tempArr);
            this.$set(this.mValue, "optionStyle", tempArr);
            console.log("this.mValue.optionStyle", this.mValue.optionStyle);
          }
        });
      }
    },
    handleRadio(val) {
      this.$bus.$emit("form-config", val);
    },
    editOption() {
      // console.log("817回显==>editOption=>this.mValue.checkedOption", this.mValue.checkedOption);
      // console.log("817回显==>editOption=>this.formData.formOptions", this.formData.formOptions);
      // console.log("817回显==>editOption=>this.mValue.tempFormOptions", this.mValue.tempFormOptions);
      // console.log("817回显==>editOption=>this.showSeleceOption", this.mValue.showSeleceOption);
      this.dialogVisible = true;
      if (!this.mValue.tempFormOptions?.length) {
        this.formData = {
          formOptions: [
            {
              optionGroup: this.$cloneDeep(this.mValue.checkedOption),
            },
          ],
        };
      } else {
        // 原来已经配置了  回显
        console.log("817 h 回显==>hhh", this.formData.formOptions);
        this.formData.formOptions = this.mValue.tempFormOptions;
      }

      console.log("this.formData", this.formData);
    },
    addFormOptions() {
      this.formData = this.$cloneDeep(this.formData);
      this.formData.formOptions.push({
        optionGroup: this.handleData(this.mValue.checkedOption),
      });
      console.log("addFormOptions: ", this.formData, this.formData.formOptions);
    },
    subFormOptions(index) {
      console.log("subFormOptions=index: ", index, this.formData.formOptions);
      this.formData = this.$cloneDeep(this.formData);
      this.$nextTick(() => {
        this.formData.formOptions.splice(index, 1);
      });
    },
    conformOption() {
      console.log("817 conformOption=>.formData: ", this.formData);
      let tempSeleceOption = [];
      tempSeleceOption = this.mValue.showSeleceOption;
      this.mValue.showSeleceOption = [];
      this.mValue.tempFormOptions = this.formData.formOptions;
      console.log("conformOption==>", this.formData);
      this.formData.formOptions.forEach((elem) => {
        elem.value = "";
      });

      for (let index = 0; index < this.formData.formOptions.length; index++) {
        const element = this.formData.formOptions[index];
        for (let j = 0; j < element.optionGroup.length; j++) {
          const opt = element.optionGroup[j];
          if (!opt.selectVal) {
            alert("选项中不允许有空值!");
            return;
          }
          element.value += opt.selectVal + "+";
        }
      }

      let values = this.formData.formOptions.map((item) => item.value);
      let valuesSet = new Set(values);

      if (valuesSet.size !== values.length) {
        alert("有重复选项,请重新选择");
        return;
      }
      this.dialogVisible = false;
      this.formData.formOptions.forEach((ele) => {
        console.log("ele: ", ele);
        if (ele.value?.indexOf("undefined") > -1) {
          ele.value = ele.value.replace("undefined", "");
        }
        this.mValue.showSeleceOption.push({
          val: ele.value.substring(0, ele.value.length - 1),
          qrcode: "",
          courseId: [],
          courseIdsStr: "",
          mOptions: {
            note: "只允许选择100个课程",
            limit: 100,
            batch: true,
          },
        });
      });
      // 新旧对比 如果有原来的选项,赋值回显
      this.mValue.showSeleceOption.forEach((fo) => {
        const tempF = tempSeleceOption.find((fi) => fi.val === fo.val);
        if (tempF) {
          fo.qrcode = tempF.qrcode;
          fo.courseId = tempF.courseId;
        }
      });
      // console.log("conformOption==>22", this.mValue.showSeleceOption, this.formData);
    },

    handleData(data) {
      console.log("data: ", data);
      const _data = this.$cloneDeep(data);
      _data?.forEach((it) => (it.selectVal = ""));
      return _data;
    },
    openCoueseSelect(index) {
      if (index > -1) {
        this.$refs.courseRef[index].open();
      } else {
        this.$refs.courseRef.open();
      }
    },
    handleIdstr(courseIds) {
      let strs = "";
      courseIds?.length > 0 &&
        courseIds.forEach((ele) => {
          strs += ele.number + ",";
        });
      if (strs?.indexOf("undefined") > -1) {
        istrs = strs.replace("undefined", "");
      }
      return strs || "";
    },
  },
  created() {
    this.checkedOptionString = this.mValue.checkedOption.map((item) => item.title);
    console.log("817=>created=>value=>SchemaForm", this.mValue, this.checkedOption);
  },
};
</script>
<style lang="less">
.schema-form {
  margin-left: 10px;
  margin-top: 5px;
  .fontbold {
    font-weight: bold;
    font-size: 14px;
  }
  .border1 {
    border: 1px solid #ccc;
    width: 95%;
    margin: 10px;
  }
  .border2 {
    border: 1px solid #ccc;
    width: 95%;
    margin: 10 0px;
  }
  .form-label-text {
    display: flex;
    color: #969799;
    font-size: 13px;
  }
  .formdata-config {
    border: 1px solid #ccc;
    width: 90%;
  }
  .no-formdata-config {
    border: 1px solid #ccc;
    width: 90%;
    padding: 10px;
  }
  .edit-btn {
    border: 1px solid #ccc;
    text-align: center;
    width: 60%;
    margin: 5px auto;
    cursor: pointer;
  }
  .config-item {
    border: 1px solid #ccc;
    width: 90%;
    padding: 10px;
    margin: 5px;
    .wrap-title {
      .el-collapse-item__header {
        height: auto;
        line-height: normal;
      }
    }
  }
  .w99 {
    margin: auto 0;
  }
  .single-option {
    display: flex;
    align-items: center;
    width: 95%;
    justify-content: space-between;
    margin: 5px;
  }
}
.el-form {
  position: relative;
}
.schema-form-dialog {
  .formOptions {
    position: relative;
  }
  .add-btn {
    border: 1px solid blueviolet;
    font-size: 15px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 2px;
    text-align: center;
    position: absolute;
    top: -40px;
    right: 30px;
  }
  .sub-btn {
    border: 1px solid blueviolet;
    font-size: 15px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 2px;
    text-align: center;
    margin: 4px 20px;
  }
}
.schema-form-item {
  display: flex;
}

.el-dialog__body .schema-form-item .el-form-item__content {
  margin-left: 15px !important;
}
</style>
