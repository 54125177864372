var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticClass: "site-nav",
      attrs: { value: _vm.value, size: "small" },
      on: { change: _vm.change },
    },
    _vm._l(_vm.nav, function (item) {
      return _c("el-option", {
        key: item.client_id,
        attrs: { label: item.name, value: item.client_id },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }