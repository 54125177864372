var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "configure-item",
    { attrs: { label: _vm.label } },
    [
      _c(
        "el-select",
        _vm._b(
          {
            attrs: { disabled: "", size: "small" },
            model: {
              value: _vm.mValue,
              callback: function ($$v) {
                _vm.mValue = $$v
              },
              expression: "mValue",
            },
          },
          "el-select",
          _vm.mOptions,
          false
        ),
        _vm._l(_vm.freshPrize, function (item) {
          return _c("el-option", {
            key: item.uuid,
            attrs: { label: item.name, value: item.uuid },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }