<template>
  <div>
    <!-- -->
    <div class="code-select">
      <div><el-button type="primary" @click="$refs.wxRrList.open()">去选择</el-button></div>
      <div class="code-name">
        <span v-if="seriesLiveObj?.name"> 当前选择：{{ seriesLiveObj?.name }}</span>
        <span v-else>暂未选择</span>
      </div>
    </div>
    <SeriesLiveList ref="wxRrList" @afterSelect="handleAfterSelect"></SeriesLiveList>
  </div>
</template>

<script>
import SeriesLiveList from "./SeriesLiveList.vue";
export default {
  props: {
    seriesLiveObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    SeriesLiveList,
  },

  data() {
    return {};
  },
  methods: {
    handleAfterSelect(v) {
      console.log("0416 v: ", v);
      this.seriesLiveObj = v;
      this.$emit("LiveObjEmit", this.seriesLiveObj);
    },
  },
  mounted() {
    console.log("0416 this.seriesLiveObj", this.seriesLiveObj);
  },
};
</script>

<style lang="less" scoped>
.code-select {
  display: flex;
  align-items: center;
  .code-name {
    margin-left: 20px;
  }
}
</style>
