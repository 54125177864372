<template>
  <el-dialog
    :width="dialogWidth"
    top="5vh"
    title="活码选择"
    :visible.sync="innerVisible"
    append-to-body
    @close="closeDialog"
  >
    <el-form :inline="true" :model="serachForm" class="demo-form-inline">
      <el-form-item label="渠道活码名称" style="width: 290px">
        <el-input v-model="serachForm.name" placeholder="请输入渠道活码名称"></el-input>
      </el-form-item>
      <el-form-item label="渠道活码Code" style="width: 290px">
        <el-input v-model="serachForm.code" placeholder="请输入渠道活码Code"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" size="medium" icon="el-icon-search" @click="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      ref="table"
      :data="tableData"
      element-loading-text="加载中 ..."
      border
      header-align="center"
      highlight-current-row
      @current-change="handleCurrentChange"
    >
      <el-table-column type="index" align="center" label="序号" width="50" />
      <el-table-column label="渠道活码code" prop="code" align="center" header-align="center" />
      <el-table-column label="渠道活码名称" prop="name" align="center" header-align="center" />
      <el-table-column
        label="渠道来源"
        prop="utm_source_name"
        align="center"
        header-align="center"
      />
      <el-table-column label="创建人" prop="creator_name" align="center" header-align="center" />
      <el-table-column label="创建时间" prop="create_time" align="center" header-align="center" />
    </el-table>
    <div class="pagination-container">
      <pagination
        :total="count"
        :page.sync="pagination.page"
        :limit.sync="pagination.size"
        @pagination="fetchData"
      />
    </div>
    <div style="width: 100%; text-align: center">
      <el-button type="primary" @click="confirmHandler">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getCommonCodes } from "@/api/common";
export default {
  name: "WxRrList",
  data() {
    return {
      dialogWidth: "80%",
      innerVisible: false,
      serachForm: {
        code: "",
        name: "",
      },
      tableData: [],
      count: 0,
      pagination: {
        page: 1,
        size: 10,
      },
      selectResult: {},
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    open() {
      this.innerVisible = true;
    },
    fetchData() {
      const requestBody = { ...this.serachForm, ...this.pagination };
      getCommonCodes(requestBody).then((res) => {
        this.count = res.data.count;
        this.tableData = res.data.results;
      });
    },
    search() {
      this.pagination.page = 1;
      this.fetchData();
    },
    confirmHandler() {
      this.$emit("afterSelect", this.selectResult);
      this.serachForm.name = "";
      this.serachForm.code = "";
      this.innerVisible = false;
    },
    closeDialog() {
      this.serachForm.name = "";
      this.serachForm.code = "";
      this.innerVisible = false;
    },
    handleCurrentChange(currentRow) {
      console.log("currentRow: ", currentRow);
      this.selectResult = currentRow;
    },
    priceFormatter(row) {
      if (row.price) {
        return Number(Number(row.price) / 100).toFixed(2);
      }
      return 0;
    },
  },
};
</script>

<style></style>
