var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: {
        effect: "light",
        content: _vm.label,
        placement: "bottom",
        "open-delay": 200,
        disabled: _vm.label == "",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "mode-select-item flex-center",
          class: [
            _vm.value == _vm.mode.mValue ? "mode-select-item-active" : "",
          ],
          on: { click: _vm.clickItem },
        },
        [
          _vm.icon
            ? _c("i", {
                staticClass: "icon iconfont f20 f-bold",
                class: _vm.icon,
              })
            : _c("span", [_vm._v(_vm._s(_vm.label))]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }