var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "请选择跳转页面", visible: _vm.show, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "tabs mb30" },
        _vm._l(_vm.tabs, function (tab, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "tab flex-center",
              class: [_vm.value.type == tab.type ? "tab-active" : ""],
              on: {
                click: function ($event) {
                  return _vm.changeTab(tab.type)
                },
              },
            },
            [_vm._v(" " + _vm._s(tab.label) + " ")]
          )
        }),
        0
      ),
      _vm.value.type == "fixed"
        ? [
            _c(
              "el-select",
              {
                staticStyle: { width: "250px" },
                attrs: { placeholder: "请选择要跳转的微页面", size: "mini" },
                model: {
                  value: _vm.value.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "id", $$v)
                  },
                  expression: "value.id",
                },
              },
              _vm._l(_vm.fixedPages, function (page, i) {
                return _c("el-option", {
                  key: i,
                  attrs: { label: page.name, value: page.id },
                })
              }),
              1
            ),
          ]
        : _vm._e(),
      _vm.value.type == "custom"
        ? [
            _c(
              "el-select",
              {
                staticStyle: { width: "250px" },
                attrs: {
                  placeholder: "请选择要跳转的自定义页面",
                  size: "mini",
                },
                model: {
                  value: _vm.value.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "id", $$v)
                  },
                  expression: "value.id",
                },
              },
              _vm._l(_vm.customPages, function (page, i) {
                return _c("el-option", {
                  key: i,
                  attrs: { label: page.name, value: page.id },
                })
              }),
              1
            ),
          ]
        : _vm._e(),
      _vm.value.type == "link"
        ? [
            _c("el-input", {
              staticStyle: { width: "250px" },
              attrs: { placeholder: "请输入外部h5链接地址", size: "mini" },
              model: {
                value: _vm.value.id,
                callback: function ($$v) {
                  _vm.$set(_vm.value, "id", $$v)
                },
                expression: "value.id",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }