<template>
  <div class="form-event">
    <div class="form-event-title">提交事件类型</div>
    <div class="form-event-label-text">
      <div>{{ label }}</div>
      <div class="form-event-body">
        <el-radio-group v-model="mValue.submitType" @change="handleRadio">
          <el-radio v-for="(item, index) in data" :key="index" :label="item.value">
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <template v-if="mValue.submitType === 'popup'">
      <div class="f-red f13 mb15 pl10 pr10"></div>
      <div class="f-grey f12 mb15 pl10 pr10">上传图片</div>
      <div class="nav ml10 mr10 mb20">
        <ImgpondMy :count="1" v-model="mValue.imagePopup" />
      </div>
      <div class="f-red f13 mb15 pl10 pr10"></div>
      <div class="f-grey f12 mb15 pl10 pr10">弹窗名称(必填)</div>
      <div class="nav ml10 mr10 mb20">
        <el-input v-model="mValue.popupName" size="small" placeholder="请输入"></el-input>
      </div>
      <JumpType v-model="mValue.jumpData" :formConfigVal="formConfigVal" />
    </template>

    <div class="form-event-label-text">
      <div>发送短信</div>
      <div class="form-event-body">
        <el-radio-group v-model="mValue.sendMessage">
          <el-radio label="yes">是</el-radio>
          <el-radio label="no">否</el-radio>
        </el-radio-group>
      </div>
    </div>

    <template v-if="mValue.sendMessage === 'yes'">
      <div class="f-red f13 mb15 pl10 pr10"></div>
      <div class="form-label-text">
        <div class="w99">短信任务ID</div>
        <div class="form-body">
          <el-input v-model="mValue.messageTaskId" size="small" placeholder="请输入"></el-input>
        </div>
        <div class="ml10">
          <el-button type="primary" @click="querySMS">查询短信</el-button>
        </div>
      </div>
      <div class="f-red f13 mb15 pl10 pr10"></div>
      <div class="form-label-text" v-if="mValue.noticeName">
        <div class="w99">通知名称</div>
        <div class="form-body">
          <el-input disabled v-model="mValue.noticeName" size="small" placeholder=""></el-input>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import JumpType from "@/components/JumpType";
import { getNoticeName } from "@/api/common.js";
import { Message } from "element-ui";

export default {
  name: "SchemaFormEvent",

  mixins: [schemaMixin],
  components: {
    JumpType,
  },
  data() {
    return {
      jumpTypeShow: true,
      formConfigVal: "notconfig",
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    mValue: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        console.log("424 mValue=>newValue:777+++ ", newValue);
        console.log("424 mValue=>oldValue:888+++", oldValue);
      },
    },
  },

  computed: {},

  methods: {
    async querySMS() {
      console.log("this.mValue.messageTaskId;: ", this.mValue.messageTaskId);
      if (!this.mValue.messageTaskId.trim()) {
        Message({
          message: "请输入查询短信",
          type: "error",
        });
        return;
      }
      const res = await getNoticeName(this.mValue.messageTaskId.trim());
      if (res.code === 20000 && res.data.name) {
        this.mValue.messageTaskId = this.mValue.messageTaskId.trim();
        this.mValue.noticeName = res.data.name;
      } else {
        this.mValue.noticeName = "";
        Message({
          message: "查询失败",
          type: "error",
        });
      }
    },
    handleRadio(val) {
      console.log("handleRadio==223333: ", val);
    },
  },
  created() {
    setTimeout(() => {
      console.log("SchemaForm==>Event--mValue: ", this.mValue);
      console.log("SchemaForm==>Event--value: ", this.value);
    }, 100);
  },
  mounted() {
    this.$bus.$on("form-config", (data) => {
      this.formConfigVal = data;
    });
  },
  // 在销毁前解绑$bus
  beforeDestroy() {
    this.$bus.off("form-config");
  },
};
</script>

<style lang="less" scoped>
.form-event {
  padding: 10px;
  background: #fff;
  margin: 10px;
  border: 1px solid #ccc;
  .form-event-title {
    font-size: 15px;
  }
  .form-event-label-text {
    display: flex;
    color: #969799;
    font-size: 13px;
  }

  .form-event-body {
    margin-left: 20px;
  }
  .w99 {
    margin: auto 0;
  }
  .form-label-text {
    display: flex;
    color: #969799;
    font-size: 13px;
  }
}
</style>
