var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-switch", {
    class: _vm.switchClass,
    attrs: {
      disabled: "",
      "active-value": true,
      "inactive-value": false,
      "active-color": "#13ce66",
      "inactive-color": "#ff4949",
      "active-text": "是",
      "inactive-text": "否",
    },
    nativeOn: {
      click: function ($event) {
        return _vm.switchHandle()
      },
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }