import axios from "@/utils/request";
const outApi = process.env.VUE_APP_API_URL;

// 获取oss
export function aliSts() {
  return axios.get("/admin_api/v1/common/ali_sts");
}

// 创建人列表
export function getCreatorList() {
  return axios.get("/admin_api/v1/common/creator");
}

// 课程列表 /admin_api/v1/common/new/products
export function getCourseList(params) {
  return axios.get("/admin_api/v1/common/new/products", {
    params,
  });
}
// 课程列表 /admin_api/v1/common/deposits
export function getDepositList(params) {
  return axios.get("/admin_api/v1/common/deposits", {
    params,
  });
}

// 优惠券列表
export function getCouponList(params) {
  return axios.get("/admin_api/v1/common/coupons", { params });
}

// 分类列表
export function getCategory(params) {
  return axios.get("/admin_api/v1/common/classifies", {
    params,
  });
}
// 提交页面信息
/**
 *
 * @param {*} id  string
 * @param {*} data {content,extra}
 * @returns
 */
export function submitPageInfo(id, data) {
  return axios.post(`/admin_api/v1/activity/${id}/content`, data);
}

// 获取页面详情
export function getPageInfo(id) {
  return axios.get(`/admin_api/v1/activity/${id}/content`);
}

//

// 搜索组织架构
export function getUserTree(params = { is_fetch_all: true, type: "STAFF" }) {
  return axios.get(`/admin_api/v1/ww_user/tree/nodes`, { params });
}

// 获取表单配置
export function getFormConfig(id) {
  return axios.get(`${outApi}/opt/v1/public/forms/${id}`);
}

// 根据任务Id获取通知名称
export function getNoticeName(id) {
  return axios.get(`/admin_api/v1/common/msg/info/${id}`);
}

// 渠道活码列表
export function getCommonCodes(params) {
  return axios.get(`/admin_api/v1/common/codes`, { params });
}

// 直播列表
export function getCommonlives(params) {
  return axios.get(`/admin_api/v1/common/lives`, { params });
}
