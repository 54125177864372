import axios from "axios";
import { Message, MessageBox } from "element-ui";
import store from "../store";
import { getToken } from "@/utils/auth";
import Cookies from "js-cookie";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "AUTH-SYS": "admin",
  },
  timeout: 5000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers["token"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改

      if (config.url.indexOf("opt/v1/public/forms/") > -1) {
        delete config.headers["token"];
      }
    }
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  async ({ data, headers }) => {
    if (
      headers["content-type"] ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
    ) {
      return data;
    }
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    if (data.code !== 20000) {
      Message({
        message: data.msg || "网络错误",
        type: "error",
      });

      // 50015  access_token不合法
      // 50021: access_token过期,
      // 50022: access_token失效,
      const errCode = [61000, 50021, 50022, 50015];
      if (errCode.includes(data.code)) {
        MessageBox.confirm("你已被登出，可以取消继续留在该页面，或者重新登录", "确定登出", {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          store.dispatch("FedLogOut");
        });
      }
      return Promise.reject(data);
    } else {
      return data;
    }
  },
  (error) => {
    Message({
      message: error.message,
      type: "error",
    });
    return Promise.reject(error);
  }
);

export default service;
