var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { staticClass: "wrap-label", attrs: { name: "1" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "cus-header-icon" }),
                _vm._v(" " + _vm._s(_vm.label)),
              ]),
              _c(
                "div",
                { staticClass: "wrap-body" },
                [
                  _vm._t("default"),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.line,
                        expression: "line",
                      },
                    ],
                    staticClass: "wrap-line",
                  }),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }