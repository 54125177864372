import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 进度条
import NProgress from "nprogress";
import "nprogress/nprogress.css";

// 自动转化根节点字体
import "lib-flexible/flexible.js";

// 拖拽
import draggable from "vuedraggable";
Vue.component("draggable", draggable);

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.less"; // global css
import "@wangeditor/editor/dist/css/style.css";

// 工具库
import _ from "lodash-es";
Vue.prototype._ = _;

// element from 验证
import ElementVerify from "element-verify";
Vue.use(ElementVerify);

// 注册全局分页
// import Pagination from "@/components/Pagination";
// Vue.component("Pagination", Pagination);

// 全局注册组件
import "@/utils/globalRegister.js";
import globalMethods from "@/utils/globalMethods";
Vue.use(globalMethods);
//
import ImgpondMy from "@/components/ImgpondMy";
Vue.component("ImgpondMy", ImgpondMy);
// 注册全局指令
import permission from "@/directive/permission/index.js";
Vue.directive("permission", permission);

// 注册全局过滤器
import * as filters from "./filters";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 全局路由守卫
import { guardFunc } from "@/utils/permission";

router.beforeEach(guardFunc);

router.afterEach(() => {
  NProgress.done();
});

Vue.use(ElementUI, { size: "small", zIndex: 3000 });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    // this就是Vue实例对象vm   ,vm上有$on,$emit,$off，直接用它就行
    Vue.prototype.$bus = this; // 安装全局事件总线
  },
}).$mount("#app");
