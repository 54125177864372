import { cloneDeep, isArray, isObject, random } from "lodash-es";
import { filterParameters, relationalOperators } from "@/api/rule";
export default {
  // namespaced: true,
  state: {
    conditions: [
      {
        id: `c-${new Date().getTime() + random(1, 100)}`,
        parent_id: "root",
        property_name: "",
        property_type: "",
        condition_value: "",
        condition_relation_type: "",
      },
    ],
    conditionGroups: [
      {
        id: "root",
        parent_id: null,
        condition_logic_type: "AND",
        conditions: null,
        child_condition_group: null,
      },
    ],
    property_name_options: [],
    condition_relation_type_options: [],
  },
  getters: {
    conditions: (state) => state.conditions,
    conditionGroups: (state) => state.conditionGroups,
    conditionGroupTree: (state) => {
      const new_state = conditionsAppendGroup(state.conditions, state.conditionGroups);
      const [_, treeData] = listToTree(new_state);
      return treeData.length > 0 ? treeData[0] : {}; //[{id:'root'}]
    },
    property_name_options: (state) => state.property_name_options,
    condition_relation_type_options: (state) => state.condition_relation_type_options,
  },
  mutations: {
    // 初始化重置项目
    resetCondition_groups(state) {
      state.conditionGroups = [
        {
          id: "root",
          parent_id: null,
          condition_logic_type: "AND",
          conditions: null,
          child_condition_group: null,
        },
      ];
      state.conditions = [
        {
          id: `c-${new Date().getTime() + random(1, 100)}`,
          parent_id: "root",
          property_name: "",
          property_type: "",
          condition_value: "",
          condition_relation_type: "",
        },
      ];
    },
    initProperty_name_options(state, data) {
      state.property_name_options = data;
    },
    initCondition_relation_type_options(state, data) {
      state.condition_relation_type_options = data;
    },
    updateConditionGroupTree(state) {
      const new_state = conditionsAppendGroup(state.conditions, state.conditionGroups);
      const [_, treeData] = listToTree(new_state);
      state.conditionGroupTree = treeData.length > 0 ? treeData[0] : {}; //[{id:'root'}]
    },
    setLogicType(state, id) {
      const _conditionGroups = [...state.conditionGroups];
      state.conditionGroups = _conditionGroups.map((item) => {
        item.id === id &&
          (item.condition_logic_type = item.condition_logic_type === "AND" ? "OR" : "AND");
        return item;
      });
    },
    //Condition 操作
    setConditions(
      state,
      data = {
        parent_id: "root",
        property_name: "",
        property_type: "",
        condition_value: "",
        condition_relation_type: "",
      }
    ) {
      const _conditions = [...state.conditions];
      if (!data.id) {
        data.id = `c-${new Date().getTime() + random(1, 100)}`;
        _conditions.push(data);
      } else {
        const existingInd = _conditions.findIndex((item) => {
          return item.id === data.id;
        });
        if (existingInd == -1) {
          _conditions.push(data);
        } else {
          _conditions[existingInd] = { ..._conditions[existingInd], ...data };
        }
      }
      console.log("_conditions", _conditions);
      state.conditions = _conditions;
    },
    delConditions(state, id) {
      state.conditions = state.conditions.filter((item) => {
        return item.id !== id;
      });
    },
    // Condition_groups 操作
    setConditionGroups(
      state,
      conditionGroup = {
        parent_id: "root",
        condition_logic_type: "AND",
        conditions: null,
        child_condition_group: null,
      }
    ) {
      const _conditionGroups = [...state.conditionGroups];
      const existingInd = conditionGroup.id
        ? state.conditionGroups.findIndex((item) => {
            return item.id === conditionGroup.id;
          })
        : -1;

      conditionGroup.id = conditionGroup.id
        ? conditionGroup.id
        : `cg-${new Date().getTime() + random(1, 100)}`;

      existingInd == -1
        ? _conditionGroups.push(conditionGroup)
        : (_conditionGroups[existingInd] = { ..._conditionGroups[existingInd], ...conditionGroup });

      state.conditionGroups = _conditionGroups;
      state.conditions = [
        ...state.conditions,
        {
          id: `c-${new Date().getTime() + random(1, 100)}`,
          parent_id: conditionGroup.id,
          property_name: "",
          property_type: "",
          condition_value: "",
          condition_relation_type: "",
        },
      ];
    },
    delConditionGroups(state, id) {
      if (id === "root") {
        return;
      }
      state.conditions = state.conditions.filter((item) => {
        return item.parent_id !== id;
      });
      state.conditionGroups = state.conditionGroups.filter((item) => {
        return item.id !== id;
      });
    },
    treeRuleToList(state, tree) {
      const [condition_group, conditions] = treeToList(tree, state.property_name_options);
      console.log("condition_group, conditions", condition_group, conditions);
      state.conditionGroups = condition_group;
      state.conditions = conditions;
    },
  },
  actions: {
    async InitOptions({ commit }, rule_type) {
      const { data: Property_name_options } = await filterParameters({ rule_type });
      commit("initProperty_name_options", Property_name_options);
      const { data: Condition_relation_type_options } = await relationalOperators();
      const condition_relation_type_options = Object.keys(Condition_relation_type_options).map(
        (item) => {
          return {
            value: item,
            label: Condition_relation_type_options[item],
          };
        }
      );
      commit("initCondition_relation_type_options", condition_relation_type_options);
    },
  },
};

export function listToTree(list) {
  const [map, treeData] = [{}, []];

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].child_condition_group = [];
  }

  for (let i = 0; i < list.length; i += 1) {
    const node = list[i];
    if (node.parent_id && list[map[node.parent_id]]) {
      list[map[node.parent_id]].child_condition_group.push(node);
    } else {
      treeData.push(node);
    }
  }
  return [map, treeData, list];
}

export function treeToList(_tree, property_name_options) {
  if (!isObject(_tree)) return [[], []];
  const tree = cloneDeep(_tree);
  if (!tree.parent_id && !tree.id) {
    tree.id = "root";
    tree.parent_id = null;
  }

  const condition_group_list = [{ ...tree, child_condition_group: null, conditions: null }];

  const conditions = tree.conditions.map((item) => {
    const property = property_name_options.find((p) => p.name === item.property_name);
    const ele_type = property ? property.element_type : "";

    return {
      ...item,
      parent_id: tree.id,
      id: `c-${new Date().getTime() + random(1, 100)}`,
      condition_value:
        ele_type !== "SELECT" ? item.condition_value : item.condition_value?.split(","),
    };
  });
  tree.child_condition_group = tree.child_condition_group?.map((item) => {
    return {
      ...item,
      parent_id: tree.id,
      id: `cg-${new Date().getTime() + random(1, 100)}`,
    };
  });

  if (isArray(tree.child_condition_group) && tree.child_condition_group.length > 0) {
    for (let i = 0; i < tree.child_condition_group.length; i += 1) {
      const item = tree.child_condition_group[i];
      const [group, cond] = treeToList(item, property_name_options);
      conditions.push(...cond);
      condition_group_list.push(...group);
    }
  }
  return [condition_group_list, conditions];
}
export function conditionsAppendGroup(conditions, conditionGroups) {
  const conditionGroupsCopy = cloneDeep(conditionGroups);

  const map = {};

  for (let i = 0; i < conditionGroupsCopy.length; i += 1) {
    map[conditionGroupsCopy[i].id] = i;
    conditionGroupsCopy[i].conditions = [];
  }
  for (let i = 0; i < conditions.length; i += 1) {
    const item = conditions[i];
    if (!item.parent_id) {
      continue;
    }

    const now_conditions = conditionGroupsCopy[map[item.parent_id]].conditions;
    const isArr = now_conditions && isArray(now_conditions) && now_conditions.length > 0;
    isArr
      ? conditionGroupsCopy[map[item.parent_id]].conditions.push(item)
      : (conditionGroupsCopy[map[item.parent_id]].conditions = [item]);
  }
  return conditionGroupsCopy;
}

export function condition_valueToString(_tree) {
  if (!isObject(_tree)) return {};
  const tree = cloneDeep(_tree);

  if (isArray(tree.conditions) && tree.conditions.length > 0) {
    tree.conditions = tree.conditions.map((item) => {
      let condition_value = item.condition_value;
      if (isArray(condition_value)) {
        condition_value = condition_value.join(",");
      }
      return {
        ...item,
        condition_value,
      };
    });
  }
  if (isArray(tree.child_condition_group) && tree.child_condition_group.length > 0) {
    tree.child_condition_group = tree.child_condition_group.map((item) => {
      return condition_valueToString(item);
    });
  }
  return tree;
}
