<template>
  <el-dialog
    :width="dialogWidth"
    top="5vh"
    title="添加课程"
    :visible.sync="innerVisible"
    append-to-body
  >
    <div v-if="options?.notShowDeposit">
      <el-form :inline="true" :model="serachForm" class="demo-form-inline">
        <el-form-item style="width: 150px">
          <el-input v-model="serachForm.keyword" placeholder="请输入课程ID、名称或年份"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="medium" icon="el-icon-search" @click="search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        ref="table"
        :data="tableData"
        element-loading-text="加载中 ..."
        border
        header-align="center"
        highlight-current-row
        @select="handleSelect"
        @select-all="handleSelectAll"
        @current-change="handleCurrentChange"
      >
        <el-table-column v-if="options.limit > 1" type="selection" width="39"> </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50" />
        <el-table-column label="课程ID" prop="number" align="center" header-align="center" />
        <el-table-column label="课程名称" prop="name" align="center" header-align="center" />
        <el-table-column label="适用年份" prop="year" align="center" header-align="center" />
        <el-table-column
          label="课程原价"
          prop="price"
          align="center"
          header-align="center"
          :formatter="priceFormatter"
        />
      </el-table>
      <div class="pagination-container">
        <pagination
          :total="count"
          :page.sync="pagination.page"
          :limit.sync="pagination.size"
          @pagination="fetchData"
        />
      </div>
      <div style="width: 100%; text-align: center">
        <el-button type="primary" @click="confirmHandler">确 定</el-button>
      </div>
    </div>
    <div v-else>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="课程列表" name="course"></el-tab-pane>
        <el-tab-pane label="定金商品" name="deposit"></el-tab-pane>
      </el-tabs>
      <div v-if="activeName == 'course'">
        <el-form :inline="true" :model="serachForm" class="demo-form-inline">
          <el-form-item style="width: 150px">
            <el-input
              v-model="serachForm.keyword"
              placeholder="请输入课程ID、名称或年份"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="medium" icon="el-icon-search" @click="search"
              >查询</el-button
            >
          </el-form-item>
        </el-form>

        <el-table
          ref="table"
          :data="tableData"
          element-loading-text="加载中 ..."
          border
          header-align="center"
          highlight-current-row
          @select="handleSelect"
          @select-all="handleSelectAll"
          @current-change="handleCurrentChange"
        >
          <el-table-column v-if="options.limit > 1" type="selection" width="39"> </el-table-column>
          <el-table-column type="index" align="center" label="序号" width="50" />
          <el-table-column label="课程ID" prop="number" align="center" header-align="center" />
          <el-table-column label="课程名称" prop="name" align="center" header-align="center" />
          <el-table-column label="适用年份" prop="year" align="center" header-align="center" />
          <el-table-column
            label="课程原价"
            prop="price"
            align="center"
            header-align="center"
            :formatter="priceFormatter"
          />
        </el-table>
        <div class="pagination-container">
          <pagination
            :total="count"
            :page.sync="pagination.page"
            :limit.sync="pagination.size"
            @pagination="fetchData"
          />
        </div>
      </div>
      <div v-if="activeName == 'deposit'">
        <el-form :inline="true" :model="depositSerachForm" class="demo-form-inline">
          <el-form-item style="width: 150px">
            <el-input v-model="depositSerachForm.number" placeholder="请输入ID"></el-input>
          </el-form-item>
          <el-form-item style="width: 150px">
            <el-input v-model="depositSerachForm.name" placeholder="请输入商品名称"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="medium" icon="el-icon-search" @click="searchDeposit"
              >查询</el-button
            >
          </el-form-item>
        </el-form>

        <el-table
          ref="depositTable"
          :data="depositTableData"
          element-loading-text="加载中 ..."
          border
          header-align="center"
          highlight-current-row
          @select="handleDepositTableSelect"
          @select-all="handleSelectAll"
          @current-change="handleCurrentChange"
        >
          <el-table-column v-if="options.limit > 1" type="selection" width="39"> </el-table-column>
          <el-table-column type="index" align="center" label="序号" width="50" />
          <el-table-column label="商品ID" prop="number" align="center" header-align="center" />
          <el-table-column label="商品名称" prop="name" align="center" header-align="center" />
          <el-table-column label="适用年份" prop="year" align="center" header-align="center" />
          <el-table-column
            label="课程原价"
            prop="price"
            align="center"
            header-align="center"
            :formatter="priceFormatter"
          />
        </el-table>
        <div class="pagination-container">
          <pagination
            :total="depositCount"
            :page.sync="depositPagination.page"
            :limit.sync="depositPagination.size"
            @pagination="fetchDepositData"
          />
        </div>
      </div>
      <div style="width: 100%; text-align: center">
        <el-button type="primary" @click="confirmHandler">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getCourseList, getDepositList } from "@/api/common";
export default {
  name: "CourseList",
  props: {
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
    queryparam: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      activeName: "course",
      dialogWidth: "80%",
      innerVisible: false,
      serachForm: {
        keyword: "",
      },
      depositSerachForm: {
        name: "",
        number: "",
      },
      tableData: [],
      count: 0,
      pagination: {
        page: 1,
        size: 10,
      },
      selectedData: [],
      tmpData: [],
      depositTableData: [],
      depositCount: 0,
      depositPagination: {
        page: 1,
        size: 10,
      },
    };
  },
  created() {
    console.log("5133333 created", this.options);
  },
  mounted() {
    console.log("5133333  this.options: ", this.options);
  },
  methods: {
    open(list) {
      console.log("0419 open222 list: ", list);
      this.selectedData = list;
      this.fetchData();
      this.fetchDepositData();
      this.innerVisible = true;
    },
    fetchData() {
      const requestBody = { ...this.serachForm, ...this.pagination, ...this.queryparam };
      getCourseList(requestBody).then((res) => {
        this.count = res.data.count;
        this.tableData = res.data.results;
        this.handleSelected();
      });
    },
    fetchDepositData() {
      const requestBody = {
        ...this.depositSerachForm,
        ...this.depositPagination,
        ...this.queryparam,
      };
      getDepositList(requestBody).then((res) => {
        console.log("0513  fetchDepositData: ", res);
        this.depositCount = res.data.count;
        this.depositTableData = res.data.results;
        this.handleDepositTableSelected();
      });
    },
    search() {
      this.pagination.page = 1;
      this.fetchData();
    },
    searchDeposit() {
      this.pagination.page = 1;
      this.fetchDepositData();
    },
    confirmHandler() {
      console.log("422 this.selectedData: ", this.selectedData);
      this.$emit("afterSelect", this.selectedData);
      this.innerVisible = false;
    },
    handleCurrentChange(currentRow) {
      console.log("0422 currentRow: ", currentRow);
      if (currentRow && !this.options.batch) {
        this.selectedData = Array.of(currentRow);
      }
    },
    handleSelect(selection, row) {
      console.log("0422 hand2leSelect: ", selection, row);
      if (this.options.batch) {
        const set = new Set(this.selectedData);
        const isCheck = selection.find((item) => item.uuid === row.uuid);
        if (isCheck) {
          set.add(row);
        } else {
          set.forEach((item) => {
            if (item.uuid == row.uuid) set.delete(item);
          });
        }
        this.selectedData = Array.from(set);
      }
    },
    handleDepositTableSelect(selection, row) {
      console.log("0422 handleDepositT1ableSelect: ", selection, row);
      if (this.options.batch) {
        const set = new Set(this.selectedData);
        const isCheck = selection.find((item) => item.uuid === row.uuid);
        if (isCheck) {
          set.add(row);
        } else {
          set.forEach((item) => {
            if (item.uuid == row.uuid) set.delete(item);
          });
        }
        this.selectedData = Array.from(set);
      }
    },
    //全选
    handleSelectAll(selection) {
      const set = new Set(this.selectedData);
      if (selection.length) {
        selection.forEach((item) => {
          set.add(item);
        });
      } else {
        this.list.forEach((item) => {
          set.delete(item);
        });
      }
      this.multipleSelection = Array.from(set);
    },

    handleSelected() {
      this.$nextTick(() => {
        if (this.selectedData?.length > 0) {
          this.$refs.table?.clearSelection();
          let selectuuids = this.selectedData.map((item) => {
            return item.uuid;
          });
          this.tableData.forEach((item) => {
            if (selectuuids.includes(item.uuid)) {
              this.$refs.table.toggleRowSelection(item, true);
            }
          });
        }
      });
    },
    handleDepositTableSelected() {
      this.$nextTick(() => {
        if (this.selectedData?.length > 0) {
          this.$refs.DepositTable?.clearSelection();
          let selectuuids = this.selectedData.map((item) => {
            return item.uuid;
          });
          this.tableData.forEach((item) => {
            if (selectuuids.includes(item.uuid)) {
              this.$refs.DepositTable.toggleRowSelection(item, true);
            }
          });
        }
      });
    },
    priceFormatter(row) {
      if (row.price) {
        return Number(Number(row.price) / 100).toFixed(2);
      }
      return 0;
    },
  },
};
</script>

<style></style>
