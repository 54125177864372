var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "configure-item",
        { attrs: { label: "查看效果" } },
        [
          _c("TqSwitch", {
            model: {
              value: _vm.mValue.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.mValue, "disabled", $$v)
              },
              expression: "mValue.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "configure-item",
        { attrs: { label: "弹窗类型" } },
        [
          _c(
            "el-select",
            _vm._b(
              {
                attrs: { size: "small" },
                on: { change: _vm.handleChangePopupType },
                model: {
                  value: _vm.mValue.currentShow,
                  callback: function ($$v) {
                    _vm.$set(_vm.mValue, "currentShow", $$v)
                  },
                  expression: "mValue.currentShow",
                },
              },
              "el-select",
              _vm.mOptions,
              false
            ),
            _vm._l(_vm.modelOptions, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.label, value: item.key },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.schema, function (val, index) {
        return _c(_vm.typeToComponent[val.type], {
          key: index,
          tag: "component",
          attrs: { options: val.options, label: val.label },
          model: {
            value: _vm.mValue.configs[_vm.currentType][val.fieldName],
            callback: function ($$v) {
              _vm.$set(_vm.mValue.configs[_vm.currentType], val.fieldName, $$v)
            },
            expression: "mValue.configs[currentType][val.fieldName]",
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }