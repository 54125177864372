var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "configure-item",
    { attrs: { label: _vm.label } },
    [
      _c("el-input-number", {
        staticClass: "ml60",
        attrs: { min: _vm.mOptions.min, max: _vm.mOptions.max },
        model: {
          value: _vm.mValue,
          callback: function ($$v) {
            _vm.mValue = $$v
          },
          expression: "mValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }