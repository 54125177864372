var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
      _c("div", { staticClass: "f-grey f12 mb15 pl10 pr10" }, [
        _vm._v("跳转类型"),
      ]),
      _c(
        "div",
        { staticClass: "nav ml10 mr10 mb20" },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              on: { change: _vm.handleChangeType },
              model: {
                value: _vm.value.type,
                callback: function ($$v) {
                  _vm.$set(_vm.value, "type", $$v)
                },
                expression: "value.type",
              },
            },
            _vm._l(_vm.filterTypeOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "mt10" },
            [
              _vm.value.type === 2
                ? _c(
                    "div",
                    [
                      _vm.options.addCourseBtn
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", icon: "el-icon-plus" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.courseList.open()
                                },
                              },
                            },
                            [_vm._v("添加产品")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.value.type === 3
                ? _c("el-cascader", {
                    ref: "cusCascader",
                    attrs: {
                      options: _vm.categoryOptions,
                      props: { checkStrictly: true },
                      clearable: "",
                    },
                    on: { change: _vm.handleClassifyChange },
                    model: {
                      value: _vm.value.classifyArr,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "classifyArr", $$v)
                      },
                      expression: "value.classifyArr",
                    },
                  })
                : _vm.value.type === 5
                ? _c("el-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.value.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "value", $$v)
                      },
                      expression: "value.value",
                    },
                  })
                : _vm.value.type === 8
                ? _c("el-input", {
                    staticStyle: { width: "45%" },
                    attrs: { disabled: "", value: "4009158585" },
                  })
                : _vm.value.type === 1 || _vm.value.type === 4
                ? _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入" },
                    on: { change: _vm.handleInput },
                    model: {
                      value: _vm.value.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "value", $$v)
                      },
                      expression: "value.value",
                    },
                  })
                : _vm.value.type === 9
                ? _c("WxQr", {
                    attrs: {
                      wxQrObj: _vm.value.wxQrObj,
                      formConfigVal: _vm.formConfigVal,
                    },
                    on: { WxQrObjEmit: _vm.WxQrEmit },
                  })
                : _vm.value.type === 10
                ? _c("SeriesLive", {
                    attrs: { seriesLiveObj: _vm.value.seriesLiveObj },
                    on: { LiveObjEmit: _vm.LiveEmit },
                  })
                : _vm._e(),
              _vm.tip
                ? _c("div", { staticClass: "ft13 mt5 f-red" }, [
                    _vm._v(_vm._s(_vm.tip)),
                  ])
                : _vm._e(),
              _vm.value.courseTableData && _vm.value.courseTableData.length > 0
                ? _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.value.courseTableData, stripe: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "number", label: "课程ID", width: "90" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "课程名称" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("CourseList", {
        ref: "courseList",
        attrs: { options: _vm.options },
        on: { afterSelect: _vm.handleAfterSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }