var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "select",
      class: [_vm.direction == "column" ? "flex-column" : ""],
    },
    [
      _c(
        "ul",
        {
          staticClass: "select-label",
          class: [_vm.direction == "column" ? "mb15" : ""],
        },
        [
          _c("li", { staticClass: "select-label-text" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "select-body" },
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "select-item",
            _vm._b({ key: index }, "select-item", item, false)
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }