var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "configure-item",
    { attrs: { label: _vm.label } },
    [
      _c(
        "el-date-picker",
        _vm._b(
          {
            staticClass: "cus-date-time",
            staticStyle: { width: "90%" },
            attrs: {
              type: "datetime",
              size: "small",
              "picker-options": _vm.pickerOptions,
            },
            on: { change: _vm.changeHandler },
            model: {
              value: _vm.valueTmp,
              callback: function ($$v) {
                _vm.valueTmp = $$v
              },
              expression: "valueTmp",
            },
          },
          "el-date-picker",
          _vm.mOptions,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }