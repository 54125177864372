var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "radio" },
    [
      _c("div", { staticClass: "radio-label-text" }, [
        _c("div", [_vm._v(_vm._s(_vm.label))]),
        _c(
          "div",
          { staticClass: "radio-body" },
          [
            _c(
              "el-radio-group",
              {
                on: { change: _vm.handleRadio },
                model: {
                  value: _vm.mValue.moduleytype,
                  callback: function ($$v) {
                    _vm.$set(_vm.mValue, "moduleytype", $$v)
                  },
                  expression: "mValue.moduleytype",
                },
              },
              _vm._l(_vm.data, function (item, index) {
                return _c(
                  "el-radio",
                  { key: index, attrs: { label: item.value } },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c("SchemaSelect", {
        staticClass: "mt15 mb20",
        attrs: { label: "样式类型", data: _vm.models },
        on: { change: _vm.changeModel },
        model: {
          value: _vm.mValue.model,
          callback: function ($$v) {
            _vm.$set(_vm.mValue, "model", $$v)
          },
          expression: "mValue.model",
        },
      }),
      [
        _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }, [
          _vm._v("配置图片数据"),
        ]),
        _c("div", { staticClass: "f-grey f12 mb15 pl10 pr10" }, [
          _vm._v("点击选择图片区域"),
        ]),
        _c(
          "div",
          { staticClass: "pl10 pl10" },
          [
            _c("cap-cube-layout", {
              ref: "layout",
              attrs: { row: _vm.mValue.row, model: _vm.mValue.model },
              on: { onCuurIndex: _vm.onCuurIndex },
              model: {
                value: _vm.mValue.list,
                callback: function ($$v) {
                  _vm.$set(_vm.mValue, "list", $$v)
                },
                expression: "mValue.list",
              },
            }),
          ],
          1
        ),
      ],
      _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
      _c("div", { staticClass: "f-grey f12 mb15 pl10 pr10" }, [
        _vm._v("上传图片"),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.formShow,
              expression: "formShow",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "nav ml10 mr10 mb20" },
            [
              _c("ImgpondMy", {
                attrs: { count: 1 },
                model: {
                  value: _vm.activeItem.image,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeItem, "image", $$v)
                  },
                  expression: "activeItem.image",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
          _vm.mValue.moduleytype === "advert"
            ? [
                _c("div", { staticClass: "f-grey f12 mb15 pl10 pr10" }, [
                  _vm._v("名称(必填)"),
                ]),
                _c(
                  "div",
                  { staticClass: "nav ml10 mr10 mb20" },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入" },
                      model: {
                        value: _vm.activeItem.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeItem, "name", $$v)
                        },
                        expression: "activeItem.name",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.mValue.moduleytype === "coupon"
        ? [
            _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
            _c("div", { staticClass: "radio-label-text" }, [
              _c("div", [_vm._v("优惠券类型")]),
              _c(
                "div",
                { staticClass: "radio-body" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.activeItem.couponType,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeItem, "couponType", $$v)
                        },
                        expression: "activeItem.couponType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "typecoupon" } }, [
                        _vm._v("优惠券"),
                      ]),
                      _c("el-radio", { attrs: { label: "typecouponbag" } }, [
                        _vm._v("优惠券包"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
            _c("div", { staticClass: "radio-label-text" }, [
              _c("div", [_vm._v("优惠券(包)ID")]),
              _c(
                "div",
                { staticClass: "radio-body" },
                [
                  _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入" },
                    model: {
                      value: _vm.activeItem.couponId,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeItem, "couponId", $$v)
                      },
                      expression: "activeItem.couponId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
            _c("div", { staticClass: "radio-label-text" }, [
              _c("div", [_vm._v("领取事件类型")]),
              _c(
                "div",
                { staticClass: "radio-body" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.activeItem.claimEventType,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeItem, "claimEventType", $$v)
                        },
                        expression: "activeItem.claimEventType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "prompt" } }, [
                        _vm._v("提示语"),
                      ]),
                      _c("el-radio", { attrs: { label: "popup" } }, [
                        _vm._v("弹窗"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeItem.claimEventType === "popup",
                    expression: "activeItem.claimEventType === 'popup'",
                  },
                ],
                staticClass: "radio-label-text",
              },
              [_vm._v(" 上传弹窗图片 ")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeItem.claimEventType === "popup",
                    expression: "activeItem.claimEventType === 'popup'",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "nav ml10 mr10 mb20" },
                  [
                    _c("ImgpondMy", {
                      attrs: { count: 1 },
                      model: {
                        value: _vm.activeItem.imagePopup,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeItem, "imagePopup", $$v)
                        },
                        expression: "activeItem.imagePopup",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeItem.claimEventType === "popup",
                    expression: "activeItem.claimEventType === 'popup'",
                  },
                ],
                staticClass: "radio-label-text",
              },
              [
                _c("div", [_vm._v("弹窗名称")]),
                _c(
                  "div",
                  { staticClass: "radio-body" },
                  [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入" },
                      model: {
                        value: _vm.activeItem.popupName,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeItem, "popupName", $$v)
                        },
                        expression: "activeItem.popupName",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
      (_vm.mValue.moduleytype === "advert" && _vm.jumpTypeShow) ||
      (_vm.mValue.moduleytype === "coupon" &&
        _vm.activeItem.claimEventType === "popup")
        ? _c("JumpType", {
            attrs: { options: _vm.radiosOptions },
            model: {
              value: _vm.activeItem.jumpData,
              callback: function ($$v) {
                _vm.$set(_vm.activeItem, "jumpData", $$v)
              },
              expression: "activeItem.jumpData",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }