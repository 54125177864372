<template>
  <configure-item :label="label">
    <div>
      <el-button type="primary" icon="el-icon-plus" @click="open">添加产品</el-button>
    </div>
    <CourseSelect ref="course" v-model="mValue" :options="mOptions" />
  </configure-item>
</template>

<script>
import CourseSelect from "@/components/CourseSelect";

import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaCourse",
  mixins: [schemaMixin],
  components: { CourseSelect },
  watch: {
    mValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        console.log("watch-mValue", newValue);
      },
    },
  },
  methods: {
    open() {
      console.log("this.$refs.course: ", this.$refs.course);
      console.log("0419=  4190: ", this.mOptions);
      console.log("0419=  4190: ", this.mValue);
      this.$refs.course.open();
    },
  },
};
</script>

<style></style>
