var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "configure-item",
    { attrs: { label: _vm.label } },
    [
      _c(
        "ImgpondMy",
        _vm._b(
          {
            attrs: { count: 1 },
            model: {
              value: _vm.mValue,
              callback: function ($$v) {
                _vm.mValue = $$v
              },
              expression: "mValue",
            },
          },
          "ImgpondMy",
          _vm.mOptions,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }