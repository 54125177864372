var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _vm._l(_vm.activeRoutes, function (item) {
        return [
          _vm.hasOneShowingChildren(item.children) &&
          !item.children[0].children &&
          !item.alwaysShow
            ? _c(
                "router-link",
                {
                  key: item.children[0].name,
                  attrs: { to: item.path + "/" + item.children[0].path },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      class: { "submenu-title-noDropdown": !_vm.isNest },
                      attrs: { index: item.path + "/" + item.children[0].path },
                    },
                    [
                      item.children[0].meta && item.children[0].meta.icon
                        ? _c("svg-icon", {
                            attrs: { "icon-class": item.children[0].meta.icon },
                          })
                        : _vm._e(),
                      item.children[0].meta && item.children[0].meta.title
                        ? _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(item.children[0].meta.title))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-submenu",
                { key: item.name, attrs: { index: item.name || item.path } },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      item.meta && item.meta.icon
                        ? _c("svg-icon", {
                            attrs: { "icon-class": item.meta.icon },
                          })
                        : _vm._e(),
                      item.meta && item.meta.title
                        ? _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(item.meta.title))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._l(item.children, function (child) {
                    return [
                      !child.hidden
                        ? [
                            child.children && child.children.length > 0
                              ? _c("sidebar-item", {
                                  key: child.path,
                                  staticClass: "nest-menu",
                                  attrs: { "is-nest": true, routes: [child] },
                                })
                              : _c(
                                  "router-link",
                                  {
                                    key: child.name,
                                    attrs: { to: item.path + "/" + child.path },
                                  },
                                  [
                                    _c(
                                      "el-menu-item",
                                      {
                                        attrs: {
                                          index: item.path + "/" + child.path,
                                        },
                                      },
                                      [
                                        child.meta && child.meta.icon
                                          ? _c("svg-icon", {
                                              attrs: {
                                                "icon-class": child.meta.icon,
                                              },
                                            })
                                          : _vm._e(),
                                        child.meta && child.meta.title
                                          ? _c(
                                              "span",
                                              {
                                                attrs: { slot: "title" },
                                                slot: "title",
                                              },
                                              [_vm._v(_vm._s(child.meta.title))]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }