var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schema-form" },
    [
      _c("div", { staticClass: "form-label-text" }, [
        _c("div", { staticClass: "w99" }, [_vm._v("表单关联ID")]),
        _c(
          "div",
          { staticClass: "form-body" },
          [
            _c("el-input", {
              attrs: { size: "small", placeholder: "请输入表单ID" },
              model: {
                value: _vm.mValue.formId,
                callback: function ($$v) {
                  _vm.$set(_vm.mValue, "formId", $$v)
                },
                expression: "mValue.formId",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
      _c("div", { staticClass: "form-label-text" }, [
        _c("div", { staticClass: "w99" }, [_vm._v("按钮文案(必填)")]),
        _c(
          "div",
          { staticClass: "form-body" },
          [
            _c("el-input", {
              attrs: { size: "small", placeholder: "最多6个字" },
              model: {
                value: _vm.mValue.btnText,
                callback: function ($$v) {
                  _vm.$set(_vm.mValue, "btnText", $$v)
                },
                expression: "mValue.btnText",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
      _c("div", { staticClass: "form-label-text" }, [
        _c("div", { staticClass: "w99" }, [_vm._v(_vm._s(_vm.label))]),
        _c(
          "div",
          { staticClass: "form-body" },
          [
            _c(
              "el-radio-group",
              {
                on: { change: _vm.handleRadio },
                model: {
                  value: _vm.mValue.isconfig,
                  callback: function ($$v) {
                    _vm.$set(_vm.mValue, "isconfig", $$v)
                  },
                  expression: "mValue.isconfig",
                },
              },
              _vm._l(_vm.data, function (item, index) {
                return _c(
                  "el-radio",
                  { key: index, attrs: { label: item.value } },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm.mValue.isconfig === "config"
        ? [
            _vm.radioOption.length > 0
              ? _c("div", { staticClass: "formdata-config" }, [
                  _c("p", { staticClass: "fontbold" }, [
                    _vm._v("表单数据配置"),
                  ]),
                  _c("div", { staticClass: "border1" }, [
                    _c("p", { staticClass: "fontbold" }, [_vm._v("单选标题")]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: { change: _vm.changeCheckedOption },
                            model: {
                              value: _vm.checkedOptionString,
                              callback: function ($$v) {
                                _vm.checkedOptionString = $$v
                              },
                              expression: "checkedOptionString",
                            },
                          },
                          _vm._l(
                            _vm.radioOptionString,
                            function (option, index) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: index,
                                  attrs: {
                                    label: option,
                                    disabled: _vm.optionStatus,
                                  },
                                },
                                [_vm._v(_vm._s(option))]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "edit-btn",
                        on: { click: _vm.saveCheckedOption },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(!_vm.optionStatus ? "保存" : "修改") +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _vm.mValue.checkedOption.length > 0
                    ? _c(
                        "div",
                        { staticClass: "border1" },
                        [
                          _c("p", { staticClass: "fontbold" }, [
                            _vm._v("表单事件配置"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "edit-btn",
                              on: { click: _vm.editOption },
                            },
                            [_vm._v("添加选项(组合)")]
                          ),
                          _vm._l(
                            _vm.mValue.showSeleceOption,
                            function (opt, index) {
                              return _c(
                                "div",
                                { key: opt.val, staticClass: "config-item" },
                                [
                                  _c(
                                    "el-collapse",
                                    [
                                      _c(
                                        "el-collapse-item",
                                        {
                                          staticClass: "wrap-title",
                                          attrs: {
                                            title: opt.val,
                                            name: opt.index,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "line-height": "10px",
                                                  },
                                                },
                                                [_vm._v("企微活码:")]
                                              ),
                                              _c("el-input", {
                                                attrs: {
                                                  size: "small",
                                                  placeholder: "请输入企微活码",
                                                },
                                                model: {
                                                  value: opt.qrcode,
                                                  callback: function ($$v) {
                                                    _vm.$set(opt, "qrcode", $$v)
                                                  },
                                                  expression: "opt.qrcode",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { margin: "5px 0" },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    margin: "5px 0",
                                                  },
                                                  attrs: {
                                                    type: "primary",
                                                    icon: "el-icon-plus",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openCoueseSelect(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选择课程:")]
                                              ),
                                              _c("CourseSelect", {
                                                ref: "courseRef",
                                                refInFor: true,
                                                attrs: {
                                                  options: opt.mOptions,
                                                  queryparam: {
                                                    productSysProductType: 2,
                                                    isFree: true,
                                                  },
                                                },
                                                model: {
                                                  value: opt.courseId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      opt,
                                                      "courseId",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "opt.courseId",
                                                },
                                              }),
                                              opt.courseId.length > 0
                                                ? _c("el-input", {
                                                    attrs: {
                                                      disabled: "",
                                                      value: _vm.handleIdstr(
                                                        opt.courseId
                                                      ),
                                                      size: "small",
                                                      placeholder: "请输入ID",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "border1" }, [
                    _c("p", { staticClass: "fontbold" }, [
                      _vm._v("表单样式配置"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "option-style" },
                      _vm._l(_vm.mValue.optionStyle, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "single-option" },
                          [
                            _c("div", { staticStyle: { width: "60%" } }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                            _c(
                              "div",
                              { staticStyle: { width: "35%" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择样式",
                                      "value-key": "name",
                                      width: "500",
                                    },
                                    on: { change: _vm.changeStyle },
                                    model: {
                                      value: item.styleVal,
                                      callback: function ($$v) {
                                        _vm.$set(item, "styleVal", $$v)
                                      },
                                      expression: "item.styleVal",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: 1,
                                      attrs: { label: "一行一个", value: 1 },
                                    }),
                                    _c("el-option", {
                                      key: 2,
                                      attrs: { label: "一行两个", value: 2 },
                                    }),
                                    _c("el-option", {
                                      key: 3,
                                      attrs: { label: "一行三个", value: 3 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.mValue.isconfig === "notconfig"
        ? [
            _c("div", { staticClass: "no-formdata-config" }, [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "5px 0" },
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: {
                        click: function ($event) {
                          return _vm.openCoueseSelect(-1)
                        },
                      },
                    },
                    [_vm._v("选择课程:")]
                  ),
                  _c("CourseSelect", {
                    ref: "courseRef",
                    attrs: {
                      options: _vm.mValue.options,
                      queryparam: { productSysProductType: 2, isFree: true },
                    },
                    model: {
                      value: _vm.mValue.courseId,
                      callback: function ($$v) {
                        _vm.$set(_vm.mValue, "courseId", $$v)
                      },
                      expression: "mValue.courseId",
                    },
                  }),
                  _vm.mValue?.courseId?.length > 0
                    ? _c("el-input", {
                        attrs: {
                          disabled: "",
                          value: _vm.handleIdstr(_vm.mValue.courseId),
                          size: "small",
                          placeholder: "请输入ID",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "border2" }, [
                _c("p", { staticClass: "fontbold" }, [_vm._v("表单样式配置")]),
                _c(
                  "div",
                  { staticClass: "option-style" },
                  _vm._l(_vm.mValue.optionStyle, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "single-option" },
                      [
                        _c("div", { staticStyle: { width: "60%" } }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _c(
                          "div",
                          { staticStyle: { width: "35%" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择样式",
                                  "value-key": "name",
                                  width: "500",
                                },
                                on: { change: _vm.changeStyle },
                                model: {
                                  value: item.styleVal,
                                  callback: function ($$v) {
                                    _vm.$set(item, "styleVal", $$v)
                                  },
                                  expression: "item.styleVal",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: 1,
                                  attrs: { label: "一行一个", value: 1 },
                                }),
                                _c("el-option", {
                                  key: 2,
                                  attrs: { label: "一行两个", value: 2 },
                                }),
                                _c("el-option", {
                                  key: 3,
                                  attrs: { label: "一行三个", value: 3 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "配置单选项",
            visible: _vm.dialogVisible,
            width: "40%",
            "append-to-body": "",
            "before-close": () => (_vm.dialogVisible = false),
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "schema-form-dialog" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.formData, "label-width": "80px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "add-btn",
                      on: { click: _vm.addFormOptions },
                    },
                    [_vm._v("+")]
                  ),
                  _vm._l(_vm.formData.formOptions, function (ele, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "formOptions" },
                      [
                        _c(
                          "div",
                          { staticClass: "schema-form-item" },
                          [
                            _vm._l(ele.optionGroup, function (item) {
                              return _c(
                                "el-form-item",
                                { key: item.id },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: `请选择${item.title}`,
                                        "value-key": "name",
                                        width: "500",
                                      },
                                      model: {
                                        value: item.selectVal,
                                        callback: function ($$v) {
                                          _vm.$set(item, "selectVal", $$v)
                                        },
                                        expression: "item.selectVal",
                                      },
                                    },
                                    _vm._l(item.options, function (opt) {
                                      return _c("el-option", {
                                        key: opt,
                                        attrs: { label: opt, value: opt },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "sub-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.subFormOptions(index)
                                  },
                                },
                              },
                              [_vm._v("-")]
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.conformOption },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }