var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("configure-item", { attrs: { label: _vm.label } }, [
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _c(
          "TqSwitch",
          _vm._b(
            {
              model: {
                value: _vm.mValue,
                callback: function ($$v) {
                  _vm.mValue = $$v
                },
                expression: "mValue",
              },
            },
            "TqSwitch",
            _vm.options,
            false
          )
        ),
        _vm._v("    "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }