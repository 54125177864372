var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "show-timeout": 200,
                "default-active": _vm.$route.path,
                collapse: _vm.isCollapse,
                mode: "vertical",
                "text-color": "#1f2d3d",
              },
            },
            [_c("sidebar-item", { attrs: { routes: _vm.permission_routers } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: "//oss.kaoyanvip.cn/uploads/file1573098085835.jpg" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }