var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex row-right", staticStyle: { width: "90px" } },
    [
      _c("el-color-picker", {
        attrs: { predefine: _vm.predefineColors, "show-alpha": "" },
        model: {
          value: _vm.mValue,
          callback: function ($$v) {
            _vm.mValue = $$v
          },
          expression: "mValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }