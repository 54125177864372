var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "configure-item",
    { attrs: { label: _vm.label } },
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.open },
            },
            [_vm._v("添加产品")]
          ),
        ],
        1
      ),
      _c("CourseSelect", {
        ref: "course",
        attrs: { options: _vm.mOptions },
        model: {
          value: _vm.mValue,
          callback: function ($$v) {
            _vm.mValue = $$v
          },
          expression: "mValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }