import { parseISOString } from "@/utils/index";
import dayjs from "dayjs";
/**
 * Created by jiachenpan on 16/11/18.
 */

export function isvalidUsername(str) {
  const valid_map = ["admin", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}

/* 合法uri*/
export function validateURL(textval) {
  const urlregex =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}
// 校验图片格式
export function validateImage(textval) {
  const urlregex =
    /^((https?|ftp)?:\/\/[^\s]+\/[^\s]+\.(jpg|jpeg|png|gif|bmp|webp|svg))(\?.*)?(#.*)?$/i;

  return urlregex.test(textval);
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/* 大小写字母*/
export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

// 排序值
export function validatOrder(rule, value, callback) {
  if (/^\d+$/.test(value)) {
    callback();
  } else {
    callback(new Error("排序值必须为非负整数"));
  }
}

// 时间
export function validateRange(rule, value, callback) {
  let now = new Date();
  let valueArr = value.split(",");
  if (valueArr.length < 2) {
    callback(new Error("开始结束时间都要选择"));
  }
  let isoDateStr = valueArr[0].split(" ").join("T") + ".0Z";
  let date = parseISOString(isoDateStr);
  // console.log(date)
  if (date.getTime() < now.getTime()) {
    callback(new Error("开始时间不能早于当前时间"));
  }
  callback();
}

export function validateRangeCheckStart(rule, value, callback, startDisabled) {
  let valueArr = value.split(",");
  if (valueArr.length < 2) {
    callback("开始结束时间都要选择");
  }
  if (valueArr.length == 2) {
    let startTime = dayjs(valueArr[0]);
    let entTime = dayjs(valueArr[1]);
    if (valueArr[1] == "") {
      callback("结束时间不能为空");
    }
    if (startTime.isAfter(entTime)) {
      callback("开始时间不能早于结束时间");
    }
    //startDisabled 如果开始时间不能修改的时候，不用判断开始时间
    if (!startDisabled && startTime.isBefore(dayjs())) {
      callback("开始时间不能早于当前时间");
    }
    if (entTime.isBefore(dayjs())) {
      callback("结束时间不能早于当前时间");
    }
  }

  callback();
}
