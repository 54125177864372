var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: _vm.dialogWidth,
        top: "5vh",
        title: "系列直播选择",
        visible: _vm.innerVisible,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.innerVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.serachForm },
        },
        [
          _c(
            "el-form-item",
            { staticStyle: { width: "290px" }, attrs: { label: "直播名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入直播名称", clearable: "" },
                model: {
                  value: _vm.serachForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.serachForm, "name", $$v)
                  },
                  expression: "serachForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "medium",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.search },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "table",
          attrs: {
            data: _vm.tableData,
            "element-loading-text": "加载中 ...",
            border: "",
            "header-align": "center",
            "highlight-current-row": "",
          },
          on: { "current-change": _vm.handleCurrentChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: "center",
              label: "序号",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "直播名称",
              prop: "name",
              align: "center",
              "header-align": "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "试用年份",
              prop: "year",
              align: "center",
              "header-align": "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              prop: "add_time",
              align: "center",
              "header-align": "center",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("pagination", {
            attrs: {
              total: _vm.count,
              page: _vm.pagination.page,
              limit: _vm.pagination.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.pagination, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.pagination, "size", $event)
              },
              pagination: _vm.fetchData,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { width: "100%", "text-align": "center" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandler } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }