var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    _vm._l(_vm.schema, function (s, key, index) {
      return _c(
        "li",
        { key: index },
        [
          _c(
            _vm.typeToComponent[s.type],
            _vm._b(
              {
                key: index,
                tag: "component",
                attrs: { schema: s },
                model: {
                  value: _vm.value[key],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, key, $$v)
                  },
                  expression: "value[key]",
                },
              },
              "component",
              s,
              false
            ),
            [
              s.child
                ? _c("schema-template", {
                    attrs: { schema: s.child, value: _vm.value[key] },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }