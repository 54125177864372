var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("SchemaSelect", {
        staticClass: "mt15 mb20",
        attrs: { label: _vm.label, data: _vm.models },
        on: { change: _vm.changeModel },
        model: {
          value: _vm.mValue.model,
          callback: function ($$v) {
            _vm.$set(_vm.mValue, "model", $$v)
          },
          expression: "mValue.model",
        },
      }),
      [
        _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }, [
          _vm._v("配置图片数据"),
        ]),
        _c("div", { staticClass: "f-grey f12 mb15 pl10 pr10" }, [
          _vm._v("点击选择图片区域"),
        ]),
        _c(
          "div",
          { staticClass: "pl10 pl10" },
          [
            _c("cap-cube-layout", {
              ref: "layout",
              attrs: { row: _vm.mValue.row, model: _vm.mValue.model },
              on: { onCuurIndex: _vm.onCuurIndex },
              model: {
                value: _vm.mValue.list,
                callback: function ($$v) {
                  _vm.$set(_vm.mValue, "list", $$v)
                },
                expression: "mValue.list",
              },
            }),
          ],
          1
        ),
      ],
      _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
      _c("div", { staticClass: "f-grey f12 mb15 pl10 pr10" }, [
        _vm._v("上传图片"),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.formShow,
              expression: "formShow",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "nav ml10 mr10 mb20" },
            [
              _c("ImgpondMy", {
                attrs: { count: 1 },
                model: {
                  value: _vm.activeItem.image,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeItem, "image", $$v)
                  },
                  expression: "activeItem.image",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "f-red f13 mb15 pl10 pr10" }),
          _c("div", { staticClass: "f-grey f12 mb15 pl10 pr10" }, [
            _vm._v("名称(必填)"),
          ]),
          _c(
            "div",
            { staticClass: "nav ml10 mr10 mb20" },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.activeItem.productName,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeItem, "productName", $$v)
                  },
                  expression: "activeItem.productName",
                },
              }),
            ],
            1
          ),
          _vm.jumpTypeShow
            ? _c("JumpType", {
                attrs: { options: { addCourseBtn: true } },
                model: {
                  value: _vm.activeItem.jumpData,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeItem, "jumpData", $$v)
                  },
                  expression: "activeItem.jumpData",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }