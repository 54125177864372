<template>
  <div>
    <div class="f-red f13 mb15 pl10 pr10"></div>
    <div class="f-grey f12 mb15 pl10 pr10">跳转类型</div>

    <div class="nav ml10 mr10 mb20">
      <el-select v-model="value.type" placeholder="请选择" @change="handleChangeType">
        <el-option
          v-for="item in filterTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="mt10">
        <div v-if="value.type === 2">
          <el-button
            v-if="options.addCourseBtn"
            type="primary"
            icon="el-icon-plus"
            @click="$refs.courseList.open()"
            >添加产品</el-button
          >
        </div>
        <el-cascader
          ref="cusCascader"
          :options="categoryOptions"
          :props="{ checkStrictly: true }"
          clearable
          v-model="value.classifyArr"
          v-else-if="value.type === 3"
          @change="handleClassifyChange"
        ></el-cascader>

        <el-input-number v-else-if="value.type === 5" v-model="value.value" :min="0" />
        <el-input disabled v-else-if="value.type === 8" value="4009158585" style="width: 45%" />
        <el-input
          v-else-if="value.type === 1 || value.type === 4"
          v-model="value.value"
          size="small"
          @change="handleInput"
          placeholder="请输入"
        ></el-input>
        <!-- <div v-else-if="value.type === 9">企微活码</div> -->
        <WxQr
          v-else-if="value.type === 9"
          :wxQrObj="value.wxQrObj"
          @WxQrObjEmit="WxQrEmit"
          :formConfigVal="formConfigVal"
        ></WxQr>
        <SeriesLive
          v-else-if="value.type === 10"
          :seriesLiveObj="value.seriesLiveObj"
          @LiveObjEmit="LiveEmit"
        ></SeriesLive>

        <div v-if="tip" class="ft13 mt5 f-red">{{ tip }}</div>

        <el-table
          v-if="value.courseTableData && value.courseTableData.length > 0"
          :data="value.courseTableData"
          stripe
          style="width: 100%"
        >
          <el-table-column prop="number" label="课程ID" width="90"> </el-table-column>
          <el-table-column prop="name" label="课程名称"> </el-table-column>
        </el-table>
      </div>
    </div>

    <CourseList ref="courseList" :options="options" @afterSelect="handleAfterSelect" />
  </div>
</template>

<script>
import CourseList from "../CourseSelect/CourseList.vue";
import WxQr from "@/components/WxQr";
import SeriesLive from "@/components/SeriesLive";
import { getCategory } from "@/api/common.js";
import { validateURL } from "@/utils/validate.js";
export default {
  components: {
    CourseList,
    WxQr,
    SeriesLive,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          name: "",
          type: "",
          value: "",
          seriesLiveObj: {},
          wxQrObj: {},
          classifyArr: [],
          courseTableData: [],
        };
      },
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
    formConfigVal: {
      type: String,
      default: () => {
        return "notconfig";
      },
    },
    // dataType: {
    //   type: String,
    //   default: () => "",
    // },
  },
  watch: {
    value(val) {
      console.log("0416.. value: ", val);
      if (val.type === 3) {
        this.fetchData();
      }
    },
  },
  mounted() {
    console.log("0416***this.value: ", this.value);
    if (this.value.type === 3) {
      this.fetchData();
    }
  },
  data() {
    return {
      typeOptions: [
        { label: "链接", value: 1 },
        { label: "课程", value: 2 },
        { label: "分类", value: 3 },
        { label: "咨询", value: 4 },
        { label: "定位到指定高度", value: 5 },
        // { label: "加企微", value: 6 },
        { label: " 不跳转", value: 7 },
        { label: "400电话", value: 8 },
        { label: "企微活码", value: 9 },
        { label: "系列直播", value: 10 },
      ],
      // couponTypeOptions: [
      //   { label: "链接", value: 1 },
      //   { label: "咨询", value: 4 },
      //   { label: "加企微", value: 6 },
      // ],
      categoryOptions: [],
      tip: "",
    };
  },
  computed: {
    filterTypeOptions() {
      if (this.value.options === "show400") {
        // 悬浮组件
        return [
          { label: "链接", value: 1 },
          { label: "课程", value: 2 },
          { label: "分类", value: 3 },
          { label: "咨询", value: 4 },
          { label: "定位到指定高度", value: 5 },
          // { label: "加企微", value: 6 },
          { label: " 不跳转", value: 7 },
          { label: "400电话", value: 8 },
          { label: "企微活码", value: 9 },
          { label: "系列直播", value: 10 },
        ];
      } else if (this.options.types) {
        return this.typeOptions.filter((t) => {
          return this.options.types.includes(t.value);
        });
      } else if (this.value?.options?.formJumpType) {
        console.log("this.options.formJumpType: ", this.value.options.formJumpType);
        return this.typeOptions.filter((t) => {
          return this.value.options.formJumpType.includes(t.value);
        });
      } else {
        return this.typeOptions;
      }
    },
  },
  mounted() {
    console.log("0417 jumptype==>value", this.value);
    console.log("0417 jumptype==>options==", this.options);
  },
  methods: {
    fetchData() {
      getCategory({ is_enable: 1 }).then((res) => {
        this.categoryOptions = res.data;
      });
    },
    handleChangeType(v) {
      console.log("handleChangeType=>value: ", this.value);
      this.value.value = "";
      this.value.name = null;
      this.value.courseTableData = [];
      if (v === 3) {
        this.fetchData();
      }
      const data = { ...this.value };
      this.$emit("input", data);
    },
    handleInput(v) {
      if ((this.value.type === 1 || this.value.type === 4) && !validateURL(v)) {
        this.tip = "链接地址格式错误";
        return;
      } else {
        this.tip = "";
        this.value.value = v;
        const data = { ...this.value };
        this.$emit("input", data);
      }
    },
    handleClassifyChange(v) {
      this.value.name = this.$refs.cusCascader.getCheckedNodes()[0].pathLabels.join("_");
      this.value.value = v.join(",");
      const data = { ...this.value };
      this.$emit("input", data);
    },
    handleAfterSelect(v) {
      console.log("422 v: ", v);
      this.value.courseTableData = v;
      this.value.value = v[0].uuid;
      this.value.name = v[0].name;
      const data = { ...this.value };
      this.$emit("input", data);
    },
    LiveEmit(v) {
      console.log("0416===v: ", v);
      this.value.wxQrObj = {};
      this.value.seriesLiveObj = v;
      this.value.value = v.uuid;
      this.value.name = v.name;
      const data = { ...this.value };
      this.$emit("input", data);
    },
    WxQrEmit(v) {
      console.log("0416===WxQrEmit: ", v);
      this.value.wxQrObj = v;
      this.value.seriesLiveObj = {};
      this.value.value = v.code;
      this.value.name = v.name;
      const data = { ...this.value };
      this.$emit("input", data);
    },
  },
};
</script>

<style></style>
