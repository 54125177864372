<template>
  <div>
    <div class="full">
      <el-upload
        ref="upload"
        action="#"
        list-type="picture-card"
        :limit="1"
        :multiple="false"
        :auto-upload="false"
        :on-change="handleUpload"
        :file-list="fileList"
        :accept="fileType"
      >
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="value" alt />
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span class="el-upload-list__item-delete" @click="handleRemove(file)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
    </div>

    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
import { upload } from "@/utils/upload";
import { validateImage } from "@/utils/validate";
export default {
  name: "ImgpondMy",
  props: {
    value: {},
  },
  watch: {
    // 外部传递的value 只用来回显
    value: {
      immediate: true,
      handler(newValue) {
        if (!validateImage(newValue)) {
          this.handleRemove();
        } else {
          this.fileList = [
            {
              url: newValue,
            },
          ];
        }
      },
    },
  },
  mounted() {},
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      mValue: "",
      fileList: [],
      fileType: "image/gif,image/jpeg,image/png",
    };
  },
  methods: {
    // 改变传出去
    handleUpload(file) {
      if (this.fileType.indexOf(file.raw.type) === -1) {
        this.$notify({
          title: "提示",
          message: `文件类型错误,支持[${this.fileType}]格式`,
          type: "error",
        });
        return;
      }
      upload(file.raw).then((res) => {
        let newValue = res.url;
        this.emitParent(newValue);
      });
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 删除删掉
    handleRemove() {
      this.clearUploadedImage();
      this.emitParent(null);
    },
    emitParent(newValue) {
      this.$emit("input", newValue);
      this.$emit("change", newValue);
    },
    clearUploadedImage() {
      this.$refs.upload && this.$refs.upload.clearFiles();
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.full {
  width: 148px;
  height: 148px;
  overflow: hidden;
}
::v-deep .el-upload-list__item {
  user-select: none;
  transition: none !important;

  .el-upload-list__item-thumbnail {
    object-fit: contain;
  }
}
</style>
