var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "configure-item",
    { attrs: { label: _vm.label } },
    [
      _c(
        "div",
        { staticClass: "flex col-center h32" },
        [
          !_vm.mValue.id
            ? _c(
                "div",
                { staticClass: "f12 f-theme pointer", on: { click: _vm.open } },
                [_vm._v("选择跳转到的页面")]
              )
            : [
                _c("el-tag", { attrs: { size: "small" } }, [
                  _vm._v(_vm._s(_vm.pageName)),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "ml5 f12 f-theme pointer",
                    on: { click: _vm.open },
                  },
                  [_vm._v("修改")]
                ),
              ],
        ],
        2
      ),
      _c("JumpDialog", { ref: "jump" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }