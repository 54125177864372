var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _vm._l(_vm.ys, function (y, yi) {
        return _c(
          "ul",
          { key: "y-" + yi, staticClass: "flex" },
          _vm._l(_vm.xs, function (x) {
            return _c(
              "li",
              {
                key: _vm.mergeKey(y, x),
                staticClass: "wrap-item flex-center",
                class: [
                  _vm.editKeys.includes(_vm.mergeKey(y, x)) ? "move-wrap" : "",
                ],
                attrs: {
                  "data-key": _vm.mergeKey(y, x),
                  "data-y": y,
                  "data-x": x,
                },
                on: {
                  click: function ($event) {
                    return _vm.clickWrap($event)
                  },
                  mouseover: _vm.move,
                },
              },
              [_c("i", { staticClass: "f12 icon icon-jia" })]
            )
          }),
          0
        )
      }),
      _vm._l(_vm.mValue, function (item, index) {
        return _c(
          "div",
          {
            key: "mValue" + index,
            staticClass: "edit-wrap flex-column flex-center",
            class: [_vm.cuurIndex == index ? "edit-wrap-active" : ""],
            style: _vm.getStyle(item),
            on: {
              click: function ($event) {
                return _vm.clickItem(index)
              },
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.model == "custom",
                    expression: "model == 'custom'",
                  },
                ],
                staticClass: "edit-wrap-close",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.deleteEditWrap(index)
                  },
                },
              },
              [_c("i", { staticClass: "f12 icon icon-cha-" })]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.width > 1,
                    expression: "item.width > 1",
                  },
                ],
              },
              [_vm._v("点击选中区域")]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }