<template>
  <div>
    <!-- -->
    <div class="code-select" v-if="formConfigVal == 'notconfig'">
      <div><el-button type="primary" @click="$refs.wxRrList.open()">去选择</el-button></div>
      <div class="code-name">
        <span v-if="wxQrObj?.code"> 当前选择：{{ wxQrObj.code }}</span>
        <span v-else>暂未选择</span>
      </div>
    </div>
    <WxRrList ref="wxRrList" @afterSelect="handleAfterSelect"></WxRrList>
  </div>
</template>

<script>
import WxRrList from "./WxRrList.vue";
export default {
  props: {
    wxQrObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    formConfigVal: {
      type: String,
      default: () => {
        return "notconfig";
      },
    },
  },
  components: {
    WxRrList,
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    handleAfterSelect(v) {
      console.log("0416 handleAfterSelect v: ", v);
      this.wxQrObj = v;
      this.$emit("WxQrObjEmit", this.wxQrObj);
    },
  },
  mounted() {
    console.log("0416 this.WXQ", this.wxQrObj);
  },
};
</script>

<style lang="less" scoped>
.code-select {
  display: flex;
  align-items: center;
  .code-name {
    margin-left: 20px;
  }
}
</style>
