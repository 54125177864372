var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("configure-item", { attrs: { label: _vm.label } }, [
    _c(
      "div",
      { staticStyle: { width: "340px", border: "1px solid #ccc" } },
      [
        _c("Toolbar", {
          staticStyle: { "border-bottom": "1px solid #ccc" },
          attrs: {
            editor: _vm.editor,
            defaultConfig: _vm.toolbarConfig,
            mode: _vm.mode,
          },
        }),
        _c("Editor", {
          staticStyle: { height: "200px", "overflow-y": "hidden" },
          attrs: { defaultConfig: _vm.editorConfig, mode: _vm.mode },
          on: { onCreated: _vm.onCreated },
          model: {
            value: _vm.mValue,
            callback: function ($$v) {
              _vm.mValue = $$v
            },
            expression: "mValue",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }