var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "课程设置",
        visible: _vm.dialogFormVisible,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c(
        "el-button",
        {
          attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
          on: {
            click: function ($event) {
              return _vm.$refs.courseList.open(_vm.tableData)
            },
          },
        },
        [_vm._v("添加课程")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { margin: "20px auto" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              "min-width": "120",
              prop: "number",
              label: "产品ID",
              align: "center",
              "header-align": "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "产品名称",
              "min-width": "300px",
              "show-overflow-tooltip": "",
              align: "center",
              "header-align": "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "100",
              prop: "price",
              label: "产品价格",
              align: "center",
              "header-align": "center",
              formatter: _vm.priceFormatter,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "60px",
              align: "center",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-button", {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleDelete(scope.$index)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { width: "100%", "text-align": "center" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandler } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c("CourseList", {
        ref: "courseList",
        attrs: {
          options: _vm.options,
          queryparam: _vm.queryparam,
          message2: _vm.message,
          msg2: _vm.msg1,
        },
        on: { afterSelect: _vm.handleAfterSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }