var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("img", {
      staticClass: "hamburger",
      class: { "is-active": _vm.isActive },
      attrs: {
        src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAQCAYAAADqDXTRAAAAl0lEQVQ4jdXVIQqCQRCG4WcXo8Fj2CwG7yEYFavgbSwLBrsXEYxGb2EQTBosBpVd2H/FF6Z97MvAzE5IKSngXhL+RKzxSCm9wnyoIf1Jp++kfexaSkc4YtFKusIBwy6FPAdpgC2mGflqK5MyhdWImGOTmQ81KuKGNWa41OnlO6+DtMcYp5ZSOGOi8Z7CFcsupaV/7/9emQco0BKn229jJAAAAABJRU5ErkJggg==",
      },
      on: { click: _vm.toggleClick },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }