<template>
  <div class="radio">
    <div class="radio-label-text">
      <div>{{ label }}</div>
      <div class="radio-body">
        <el-radio-group v-model="mValue.moduleytype" @change="handleRadio">
          <el-radio v-for="(item, index) in data" :key="index" :label="item.value">
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <!-- 魔方模板选择 -->
    <SchemaSelect
      class="mt15 mb20"
      label="样式类型"
      v-model="mValue.model"
      :data="models"
      @change="changeModel"
    ></SchemaSelect>
    <!-- 魔方布局 -->
    <template>
      <div class="f-red f13 mb15 pl10 pr10">配置图片数据</div>
      <div class="f-grey f12 mb15 pl10 pr10">点击选择图片区域</div>

      <div class="pl10 pl10">
        <cap-cube-layout
          ref="layout"
          :row="mValue.row"
          :model="mValue.model"
          v-model="mValue.list"
          @onCuurIndex="onCuurIndex"
        ></cap-cube-layout>
      </div>
    </template>
    <div class="f-red f13 mb15 pl10 pr10"></div>
    <div class="f-grey f12 mb15 pl10 pr10">上传图片</div>
    <div v-show="formShow">
      <div class="nav ml10 mr10 mb20">
        <ImgpondMy :count="1" v-model="activeItem.image" />
      </div>
      <div class="f-red f13 mb15 pl10 pr10"></div>
      <template v-if="mValue.moduleytype === 'advert'">
        <div class="f-grey f12 mb15 pl10 pr10">名称(必填)</div>
        <div class="nav ml10 mr10 mb20">
          <el-input v-model="activeItem.name" size="small" placeholder="请输入"></el-input>
        </div>
      </template>
    </div>

    <template v-if="mValue.moduleytype === 'coupon'">
      <div class="f-red f13 mb15 pl10 pr10"></div>
      <div class="radio-label-text">
        <div>优惠券类型</div>
        <div class="radio-body">
          <el-radio-group v-model="activeItem.couponType">
            <el-radio label="typecoupon">优惠券</el-radio>
            <el-radio label="typecouponbag">优惠券包</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="f-red f13 mb15 pl10 pr10"></div>

      <div class="radio-label-text">
        <div>优惠券(包)ID</div>
        <div class="radio-body">
          <el-input v-model="activeItem.couponId" size="small" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="f-red f13 mb15 pl10 pr10"></div>

      <div class="radio-label-text">
        <div>领取事件类型</div>
        <div class="radio-body">
          <el-radio-group v-model="activeItem.claimEventType">
            <el-radio label="prompt">提示语</el-radio>
            <el-radio label="popup">弹窗</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="f-red f13 mb15 pl10 pr10"></div>
      <div v-show="activeItem.claimEventType === 'popup'" class="radio-label-text">
        上传弹窗图片
      </div>
      <div v-show="activeItem.claimEventType === 'popup'">
        <div class="nav ml10 mr10 mb20">
          <ImgpondMy :count="1" v-model="activeItem.imagePopup" />
        </div>
      </div>
      <div v-show="activeItem.claimEventType === 'popup'" class="radio-label-text">
        <div>弹窗名称</div>
        <div class="radio-body">
          <el-input v-model="activeItem.popupName" size="small" placeholder="请输入"></el-input>
        </div>
      </div>
    </template>
    <JumpType
      v-if="
        (mValue.moduleytype === 'advert' && jumpTypeShow) ||
        (mValue.moduleytype === 'coupon' && activeItem.claimEventType === 'popup')
      "
      v-model="activeItem.jumpData"
      :options="radiosOptions"
    />
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import CapCubeLayout from "./CapCubeLayout";
import {
  initialAdvertModels,
  modelAdvertOptions,
  initialCouponModels,
  modelCouponOptions,
} from "./config";
import JumpType from "@/components/JumpType";
export default {
  name: "SchemaRadio",

  mixins: [schemaMixin],
  components: {
    CapCubeLayout,
    JumpType,
  },
  data() {
    return {
      initialModels: initialAdvertModels,
      modelOptions: modelAdvertOptions,
      formShow: true,
      jumpTypeShow: true,
      models: [],
      activeItem: {
        image: "",
        name: "",
        couponType: "typecoupon",
        couponId: "",
        claimEventType: "prompt",
        imagePopup: "",
        popupName: "",
        jumpData: {
          name: "",
          type: "",
          value: "",
          seriesLiveObj: {},
          wxQrObj: {},
        },
      },
      radiosOptions: { addCourseBtn: true, types: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    mValue: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        console.log("mValue=>newValue:777+++ ", newValue);
        console.log("mValue=>oldValue:888+++", oldValue);
      },
    },
  },

  computed: {},

  methods: {
    handleRadio(val) {
      if (val === "advert") {
        this.initialModels = initialAdvertModels;
        this.modelOptions = modelAdvertOptions;
      }
      if (val === "coupon") {
        this.initialModels = initialCouponModels;
        this.modelOptions = modelCouponOptions;
      }
      this.init();
      console.log("handleRadio==223333: ", val);
    },

    init() {
      console.log("this.modelOptions:223333 ", this.modelOptions);
      this.models = this.modelOptions.filter((v) => {
        return this.options.model.indexOf(v.value) > -1;
      });
      console.log("this.models :223333 ", this.models);
    },
    // 切换模型
    changeModel(model) {
      if (model) {
        this.formShow = true;
        // 设置模板对应行数
        let target = this.modelOptions.find((m) => m.value == model);
        this.$set(this.mValue, "row", target.row);

        // 重置模板
        this.$refs.layout.reset();

        // 设置模板对应初始数据
        if (model == "custom") {
          this.$set(this.mValue, "list", []);
        } else {
          this.$set(this.mValue, "list", this._.cloneDeep(this.initialModels[model]));
        }
      }
    },

    // 切换行数
    changeRow() {
      this.$refs.layout.reset();
    },

    onCuurIndex(itme) {
      console.log("0416 itme: ", itme);
      this.formShow = true;
      this.activeItem = itme;
      console.log("0416 this.activeItem: ", this.activeItem);
      this.jumpTypeShow = true;
    },
  },
  created() {
    this.init();
    this.handleRadio(this.mValue.moduleytype);
    setTimeout(() => {
      console.log("radio--mValue---2233334455: ", this.mValue);
      console.log("radio---: ", this.value);
      console.log("radio**** ", this.data);
      console.log("radio=====: ", this.label);
      console.log("radio=====223333: ", this.options);
    }, 100);
  },
};
</script>

<style lang="less" scoped>
.radio {
  padding: 10px;
  background: #fff;

  .radio-label-text {
    display: flex;
    color: #969799;
    font-size: 13px;
  }

  .radio-body {
    margin-left: 20px;
  }
}

.mode-radio {
  display: flex;
  justify-content: space-between;

  .mode-radio-label {
    font-size: 14px; /*no*/
    line-height: 32px; /*no*/
  }

  .mode-radio-body {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
