var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "code-select" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.$refs.wxRrList.open()
                  },
                },
              },
              [_vm._v("去选择")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "code-name" }, [
          _vm.seriesLiveObj?.name
            ? _c("span", [
                _vm._v(" 当前选择：" + _vm._s(_vm.seriesLiveObj?.name)),
              ])
            : _c("span", [_vm._v("暂未选择")]),
        ]),
      ]),
      _c("SeriesLiveList", {
        ref: "wxRrList",
        on: { afterSelect: _vm.handleAfterSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }